/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fetchProperty, fetchPropertyComments, fetchComment, newComment, updateComment } from '../../misc/apiRequests';
// import { propertiesData } from '../../misc/jsonData/propertyListingData';
// import { commentsJSON, selectedComments1JSON, selectedComments2JSON } from '../../misc/jsonData/forumData';
import PageIndex from '../../presentational/pageIndex';
import PropertyForumDisplayThumb from '../../presentational/properties/propertyForumDisplayThumb';
import '../../../assets/css/propertyForms.css';
import Swal from 'sweetalert2';

const PropertyForms = ({ user, setRedirect, handleLoader, handleModal, handleMessagesModal, handleImageModal }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const [selectedProperty, setSelectedProperty] = useState();
  const [pageType, setPageType] = useState("repair");
  const [newCommentMode, setNewCommentMode] = useState(false);
  const [commentType, setCommentType] = useState('repair');
  const [commentTitle, setCommentTitle] = useState('');
  const [commentBody, setCommentBody] = useState('');
  const [commentImages, setCommentImages] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [pageNumb, setPageNumb] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [numbRecords, setNumbRecords] = useState(0);

  const uploadsInputRef = useRef(null);

  let { id } = useParams();

  const handleSelectComment = comment => {
    if(comment.comment_id !== null) { return console.log('Cant select subcomment'); }
    setPageNumb(1);
    setSelectedComment(comment);
  };

  // const handleIndexChange = (e, nextPage = false) => {
  //   e.preventDefault();
  //   if(!nextPage && ((pageNumb - 1) !== 0)) setPageNumb(pageNumb - 1);
  //   if(nextPage && ((pageNumb + 1) <= maxPages)) setPageNumb(pageNumb + 1);
  // };

  const handleNewCommentMode = (mode = true) => {
    setNewCommentMode(!newCommentMode);
  };

  // Populate all subforums and related posts paginated by 5 posts per page
  const populateComments = () => comments.map(commentData => (
    <PropertyForumDisplayThumb
      key={commentData.id}
      comment={commentData}
      handleSelectComment={handleSelectComment}
    />
  ));

    // Ensures that the comment images uploaded doesn't go too far over 1 megabyte
    const handleCheckFileSize = e => {
      const elem = e.target;
      let overlimit = false;
      const errorList = [];
      Array.from(elem.files).forEach((x, index) => {
        if (elem.files[index].size > 1048576) {
          console.log(elem.files[index]);
          errorList.push(`${x.name} - File size too big (max size allowed: 1 megabyte)`);
          overlimit = true;
        } else { 
          const imagesList = commentImages;
          imagesList.push(elem.files[index]);
          setCommentImages(imagesList);
        }
      })
  
      if (overlimit) {
        elem.value = '';
        setCommentImages([]);
        handleMessagesModal(errorList);
      }
    };
    
    const handleSubmitNew = (e) => {
      e.preventDefault();
  
      const commentData = new FormData();
      commentData.append('comment[comment_type]', commentType);
      commentData.append('comment[title]', commentTitle);
      commentData.append('comment[body]', commentBody);
      commentImages.forEach((x) => commentData.append("comment[images][]", x))
      // commentData.append('comment[images]', commentImages);
  
      handleLoader(true);
      const propertyID = parseInt(id, 10);
      newComment(commentData, propertyID)
        .then(response => {
          if (response.success) {
            setComments(response.records);
            setMaxPages(response.records_params.max_page || 1);
            setNumbRecords(response.records_params.no_of_records)
            handleMessagesModal(response.message);
            // setRedirect(`/properties/forms/${propertyID}`)
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
  
      return null;
    };

  const handleClearImages = () => {
    const uploadsInputElem = uploadsInputRef.current;

    uploadsInputElem.value = '';
    setCommentImages([]);
  }

  // Fetch Property by ID
  useEffect(() => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      fetchProperty(propertyID)
        .then(response => {
          if (response.success) {
            setSelectedProperty(response.record);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [id, handleLoader, handleModal]);

  // Fetch Comments by Property ID and Comment Type
  useEffect(() => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      fetchPropertyComments(perPage, pageNumb, pageType, propertyID)
        .then(response => {
          if (response.success) {
            setComments(response.records);
            setMaxPages(response.records_params.max_page || 1);
            setNumbRecords(response.records_params.no_of_records)
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [id, pageNumb, handleLoader, handleModal, perPage, pageType]);

  // Redirect if unauthorized
  useEffect(() => {
    if(selectedProperty) {
      if(user?.id !== selectedProperty.resident_id && user?.id !== selectedProperty.owner_id) { setRedirect("/"); }
    }
  }, [user, user?.id, selectedProperty, setRedirect])

  // Redirect Page once comment is selected
  useEffect(() => {
    if(selectedComment !== null) {
      const propertyID = parseInt(id, 10);
      const commentID = parseInt(selectedComment?.id, 10);
      setRedirect(`/properties/forms/${propertyID}/comment/${commentID}`);
    }
  }, [id, selectedComment, setRedirect]);

  // Fetch FAKE DATA Property by ID
    // useEffect(() => {
    //   if (id) {
    //     const propertyID = parseInt(id, 10);
    //     const property = propertiesData.filter(x => x.id === propertyID)
    //     if (property[0]) {
    //       setSelectedProperty(property[0]);
    //     }
    //     else {
    //       handleModal(['Property with given ID not found']);
    //     }
    //   }
    // }, [id, handleModal]);

    // Fetch FAKE DATA Comments by Property ID
    // useEffect(() => {
    //   setComments(commentsJSON.records.slice(pageNumb - 1, (maxPages / perPage) - (perPage - pageNumb)));
    //   // console.log(`${maxPages} / ${perPage} - (${perPage} - ${pageNumb}) = ${(maxPages / perPage) - (perPage - pageNumb)}`)
    //   setMaxPages(Math.ceil(commentsJSON.record_params.no_of_records / perPage));
    //   setNumbRecords(commentsJSON.record_params.no_of_records);
    // }, [perPage, pageNumb, maxPages]);

    // Fetch FAKE DATA Comments for selected Comment
    // useEffect(() => {
    //   if(selectedComment !== null) {
    //     const propertyID = parseInt(id, 10);
    //     const commentID = parseInt(selectedComment?.id, 10);
    //     setRedirect(`/properties/forms/${propertyID}/comment/${commentID}`);
    //   }
    // }, [id, selectedComment, setRedirect]);

  // useEffect(() => {
  //   if(!user.logged_in) { setRedirect("/"); }
  // }, [user, setRedirect]);
  
  const newCommentForum = () =>
    (
      <div className='mb-2'>
        <form className="property-forms" onSubmit={handleSubmitNew} encType="multipart/form-data">
          <h4 className=''>{t('topic_summary')}</h4>
          <input
            type="text"
            value={commentTitle}
            onChange={e => setCommentTitle(e.target.value)}
            minLength="3"
            required
          />
          <h5>{t('issue_details')}:</h5>
          <textarea
            rows="4"
            cols="40"
            value={commentBody}
            onChange={e => setCommentBody(e.target.value)}
          />
          <h5>{t('f_photo')}</h5>
          <input
            type="file"
            multiple
            ref={uploadsInputRef}
            alt='Comment photo uploads'
            name="photo_uploads"
            accept="image/png, image/jpeg, image/jpg"
            // value={photoUpload}
            onChange={e => handleCheckFileSize(e)}
          />
          <button type='button' className='danger-btn' onClick={() => handleClearImages()} >{t('f_clearImage')}</button>

          <div className='mt-1'>
            <button type="button" className='danger-btn' onClick={() => handleNewCommentMode()}>{t('f_cancel')}</button>
            <button type="submit" className='ml-2'>{t('f_submit_req')}</button>
          </div>
        </form>
      </div>
    );

  return (
    <div id='PropertyForms'>
      {newCommentMode && (<div className="modal">
        <div className="modal-content" style={{minHeight: 'auto', maxHeight: 'none'}}>
          <div className='container'>
            {newCommentForum()}
          </div>
        </div>
      </div>
      )}
      <div className='container'>
        <h2 className='text-center mt-2 mb-2'>{t('property_forum')}</h2>
        <div className='property-navbar mt-2'>
          <span className="text-grey text-camel">{selectedProperty?.property_group ? 'Apartment Complex' : selectedProperty?.property_type}</span>
          {selectedProperty?.property_group && <h2 className=''>{selectedProperty?.property_group?.group_name}</h2>}
          <div className="pb-1">
            <h3 className="text-camel">{selectedProperty?.settlement || 'Settlement'}, {selectedProperty?.island}</h3>
            <div className="text-camel">{selectedProperty?.street_address}, <span className="text-caps">{selectedProperty?.po_box}</span></div>
          </div>
          {selectedProperty?.property_group && <span className="text-grey text-camel">{selectedProperty?.property_unit || 'Unit#'}</span>}
        </div>
        <div className='property-navbar mt-2'>
          <h2 className='mb-05'>{t('maintenance_req')}</h2>
          <button type='button' onClick={() => handleNewCommentMode()} className='forum-btn'>{t('new_repair_req')}</button>
          <div className='flex-row'>
            <button type='button' className='forum-btn'>{t('open')}</button>
            <button type='button' className='forum-btn'>{t('closed')}</button>
            {/* {user?.id === selectedProperty?.resident_id && <button type='button' onClick={() => handleNewCommentMode()} className='forum-btn'>New Repair request</button>} */}
          </div>
        </div>
        <div className=''>
          {/* {newCommentMode && newCommentForum()} */}
          <div className='flex-col'>
            {populateComments()}
          </div>
          <div>
          {maxPages > 1 && <PageIndex setPage={setPageNumb} setPerPage={setPerPage} page={pageNumb} perPage={perPage} maxPages={maxPages} />}
          {/* <div>
            <span>showing {perPage} record(s) per page</span><br/>
            <span>total records: {numbRecords}</span>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

PropertyForms.propTypes = {
  user: propTypes.instanceOf(Object),
  setRedirect: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired,
};

export default PropertyForms;
