/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import PageIndex from '../../../presentational/pageIndex';
import PropertyTransactionsDisplay from '../../../presentational/properties/payments/propertyTransactionsDisplay';
import { useTranslation } from 'react-i18next';
import { fetchProperties, fetchPropertyGroups, fetchPropertyTransactions } from '../../../misc/apiRequests';
import { islands } from '../../../misc/jsonData/islandData';
import Swal from 'sweetalert2';
import Exit from '../../../../assets/images/exit.svg';

import '../../../../assets/css/transactions.css';
import { numberWithCommas } from '../../../misc/misc';

const PropertyTransactions = ({ user, handleLoader, handleModal }) => {
  const { t } = useTranslation();

  const [properties, setProperties] = useState([]);
  const [groups, setGroups] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [perPage, setPerPage] = useState(500);

  const [transactions, setTransactions] = useState([]);
  const [transactionPage, setTransactionPage] = useState(1);
  const [transactionMaxPages, setTransactionMaxPages] = useState(1);
  const [transactionPerPage, setTransactionPerPage] = useState(20);

  const [address, setAddress] = useState('');
  const [island, setIsland] = useState('');
  const [settlement, setSettlement] = useState('');

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handlePropertySelect = (property) => {
    if ('property_type' in property) { setSelectedGroup(null); setSelectedProperty(property); }
    else { setSelectedProperty(null); setSelectedGroup(property); }
  };

  // Populate all payment settings for owned properties
  const populatePaymentSettings = () => {
    console.log('group', selectedGroup);
    console.log('property', selectedProperty);
    let filteredArray = selectedGroup ? selectedGroup?.properties : properties?.concat(groups);

    if (island) filteredArray = filteredArray.filter(x => x.island.toLowerCase() === island.toLowerCase());
    if (settlement) filteredArray = filteredArray.filter(x => x.settlement.toLowerCase() === settlement.toLowerCase());
    if (address) filteredArray = filteredArray.filter(x => x.street_address.toLowerCase().includes(address.toLowerCase()));
    
    return filteredArray?.map(propertyData => (
    <PropertyTransactionsDisplay
      key={propertyData.id}
      propertyInfo={propertyData}
      user={user}
      handlePropertySelect={() => handlePropertySelect(propertyData)}
    />
  ))};

  const paymentStatus = (data) => {
    if (data.cancelled) {
      return 'Cancelled';
    } else {
      return data.payment_status ? 'Paid' : 'Pending';
    }
  }

  const populateTransactions = () => transactions.map((data) => (
    <tr key={data.id}>
      <td>{data.id}</td>
      <td>${numberWithCommas(data.payment_amount)}</td>
      <td>{data.payment_invoice}</td>
      <td className={`${data.cancelled && 'text-red'} ${data.payment_status && 'text-green'}`}>{paymentStatus(data)}</td>
      {/* <td>{data.initial_deposit}</td> */}
      <td>{data.desc}</td>
      <td>{data.paid_date?.split('T')[0] || '...'}</td>
      {/* <td>{data.next_pay_date?.split('T')[0] || '...'}</td> */}
      <td className='text-camel'>{data.user.profile.first_name} {data.user.profile.last_name}</td>
      <td>{data.created_at.split('T')[0]}</td>
    </tr>
  ));

  // Populate Islands and Settlements
  const populateIslands = () => islands.map((data, index) => (
    <option className='' key={index} value={data.name}>{`${data.name}, Bahamas`}</option>
  ));
  const populateSettlements = () => islands.filter(x => x.name === island)[0]
    ?.settlements.sort((a, b) => {
      const nameA = a.toUpperCase(); // ignore upper and lowercase
      const nameB = b.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    })
    ?.map((data, index) => (
    <option className='' key={index + data} value={data}>{data}</option>
  ));

  // Grab all properties posted by current user
  useEffect(() => {
    handleLoader(true);
    const record = { per_page: perPage, page: page, };
    fetchProperties(record.per_page, record.page)
      .then(response => {
        if (response.success) {
          setProperties(response.records);
          setMaxPages(response.records_params.max_page || 1);
        }
        if (!response.success) handleModal(response.errors);
        handleLoader(false);
      });
  }, [handleLoader, handleModal, page, perPage]);

  // Grab all property groups posted by current user
  useEffect(() => {
    handleLoader(true);
    const record = { per_page: perPage, page: page, };
    fetchPropertyGroups(record.per_page, record.page)
      .then(response => {
        if (response.success) {
          setGroups(response.records);
          setMaxPages(response.records_params.max_page || 1);
        }
        if (!response.success) handleModal(response.errors);
        handleLoader(false);
      });
  }, [handleLoader, handleModal, page, perPage]);

  // Grab all transactions relevant to the selected property
  useEffect(() => {
    if (!selectedProperty) return;

    handleLoader(true);
    const record = { per_page: transactionPerPage, page: transactionPage, };
    fetchPropertyTransactions(selectedProperty?.id, record.per_page, record.page)
      .then(response => {
        if (response.success) {
          setTransactions(response.records);
          setTransactionMaxPages(response.records_params.max_page || 1);
        }
        if (!response.success) handleModal(response.errors);
        handleLoader(false);
      });
  }, [handleLoader, handleModal, transactionPerPage, transactionPage, selectedProperty]);

  return (
    <div>
      <div className='acct-menu-container'>
        <h1>{t('f_payment')}</h1>

        {user.roles.includes('renter') && <div>
          <h3>{t('propertyTransactions')}</h3>
          <form className='flex-col flex-start pb-1'>
            <span className='mb-1 size-1'>{t('select_filter')}</span>
            <div className='flex-col'>
              <span className='form-sub-text text-bold'>{t('f_streetAddress')}</span>
              <input type='text'
                className='modal-btn'
                style={{margin: 0, maxWidth: '400px'}}
                value={address}
                onChange={e => setAddress(e.target.value)}
                minLength="4"
                required
              />
            </div>
            <div className='flex-row mt-1'>
              <div className='flex-col mr-2'>
                  <span className='form-sub-text text-bold'>{t('f_island')}</span>
                  <select className='modal-btn' name="island" id="by_island" value={island} onChange={e => setIsland(e.target.value)}>
                    <option value={null}></option>
                    {populateIslands()}
                  </select>
                </div>
                <div className='flex-col'>
                  <span className='form-sub-text text-bold'>{t('f_settlement')}</span>
                  <select className='modal-btn' name="settlement" id="by_settlement" value={settlement} onChange={e => setSettlement(e.target.value)}>
                    <option value={null}></option>
                    {populateSettlements()}
                  </select>
                </div>
            </div>
            {/* <button type="submit" className='button-search'>{t('search')}</button> */}
          </form>
          <div className='flex-row'>{populatePaymentSettings()}</div>
          {maxPages > 1 && (
            <div className='text-center'>
              <PageIndex setPage={setPage} setPerPage={setPerPage} page={page} perPage={perPage} maxPages={maxPages} />
            </div>
          )}
        </div>}
      </div>
      {selectedProperty && (
        <div className="modal">
          <div className="modal-content" style={{minHeight: '80%', maxWidth: 'none', overflowX: 'auto'}}>
            <button type="button" className="modal-exit" onClick={(e) => setSelectedProperty(null)}><img src={Exit} alt='exit icon' /></button>
            <div className="container-md">
              <h3 className='text-camel text-center'>
                {`${selectedProperty?.property_group?.street_address || selectedProperty.street_address}, 
                ${selectedProperty?.property_group?.island || selectedProperty.island}, 
                ${selectedProperty?.property_group?.settlement || selectedProperty.settlement}`}
              </h3>
              <h3 className='text-camel text-center'>{selectedProperty?.property_unit}</h3>
              <table className='transact-table'>
                <tr>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Invoice No.</th>
                  <th>Status</th>
                  {/* <th>Initial Deposit</th> */}
                  <th>Desc</th>
                  <th>Paid Date YY/MM/DD</th>
                  {/* <th>Next Pay Date</th> */}
                  <th>Tenant</th>
                  <th style={{minWidth: '120px'}}>Date YY/MM/DD</th>
                </tr>
                {populateTransactions()}
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PropertyTransactions.propTypes = {
  user: propTypes.instanceOf(Object),
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired
};

export default PropertyTransactions;
