
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logoText from './assets/images/logo-text.png';
// import logoName from './assets/images/logo-name.png';
import logoSimple from './assets/images/logo-simple.png';
import Globe from './assets/images/globe.svg';
import './assets/css/App.css';
import { userLoggedIn, userLogout, removeProperty, fetchNotifications } from './components/misc/apiRequests';

import Home from './components/presentational/home';
import Login from './components/functional/users/login';
import ForgotPassword from './components/functional/users/forgotPassword';
import Register from './components/functional/users/register';
import Account from './components/functional/users/account';
import NewProperty from './components/functional/properties/newProperty';
import EditProperty from './components/functional/properties/editProperty';
import PropertyForums from './components/functional/properties/propertyForums';
// import LoginBtn from './components/presentational/users/loginBtn';
import PropertyDisplay from './components/presentational/properties/propertyDisplay';
import PropertyForumDisplay from './components/presentational/properties/propertyForumDisplay';

import Modal from './components/functional/modal';
import NoticeModal from './components/functional/noticeModal';
import ImageModal from './components/functional/imageModal';
import Loader from './components/presentational/loader';
import SearchForProperty from './components/functional/properties/searchForProperty';
import Notifications from './components/functional/users/notifications';
import ConfirmModal from './components/functional/confirmModal';
import PropertyGroupDisplay from './components/presentational/properties/propertyGroupDisplay';
import PropertyFavorites from './components/presentational/properties/propertyFavorites';
import AboutUs from './components/presentational/aboutUs';
import ContactUs from './components/presentational/contactUs';
import Swal from 'sweetalert2';
import { ProtectedRoute } from './components/functional/protectedRoute';
// import { windowWidth } from './components/misc/misc'

function App() {
  const { t, i18n } = useTranslation();
  const [currentLang, setLang] = useState('English');

  const [user, setUser] = useState({ id: null, logged_in: false, loading: true });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(null);
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState('');
  const [confirmOptions, setConfirmOptions] = useState('');
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [redirect, setRedirect] = useState(null);

  // Notifications
  const [notifications, setNotifications] = useState([]);
  const [noticePerPage, setNoticePerPage] = useState(10);
  const [noticePage, setNoticePage] = useState(1);
  const [noticeMaxPages, setNoticeMaxPages] = useState(1);
  const [newNotice, setNewNotice] = useState(false);

  const navigate = useNavigate()

  const navBar = useRef(null);
  const signUpRef = useRef(null);
  const loginRef = useRef(null);
  const searchRef = useRef(null);
  const contactRef = useRef(null);
  const featuredRef = useRef(null);
  const aboutRef = useRef(null);
  const favoritesRef = useRef(null);
  // const accountRef = useRef(null);

  const location = useLocation()

  const handleTransation = () => {
    const langList = [{ code: 'en', lang: 'English'},
                      { code: 'es', lang: 'Español' },
                      { code: 'cre', lang: 'Créole' }];
  
    let nextLang = '';
    switch (currentLang) {
      case 'English':
        nextLang = 'Español';
        break;
      case 'Español':
        nextLang = 'Créole';
        break;
      case 'Créole':
        nextLang = 'English';
        break;
      default:
        nextLang = 'English';
    }

    const code = langList.filter(e => e.lang === nextLang)[0].code;
    i18n.changeLanguage(code);
    setLang(nextLang);
  };

  const toggleNavBar = (hide = false) => {
    const navBarElem = navBar.current;
    if(hide === false) { navBarElem.classList.toggle('hide'); }
    if(hide === true) { navBarElem.className = 'hide'; }
  };

  // Toggle modal and clear status
  const handleModal = useCallback((errors = []) => {
    setErrors(errors);
  }, [setErrors]);

  const handleMessagesModal = useCallback((msg = '') => {
    setMessages(msg);
  }, [setMessages]);

  const handleConfirmModal = useCallback((options) => {
    const setOptions = { visible: options?.visible || false,
                         header: options?.header || '',
                         message: options?.message || '',
                         textbox: options?.textbox || false,
                         rating: options?.rating || false,
                         handleFunction: options?.handleFunction || null };

    setConfirmOptions(setOptions);
  }, [setConfirmOptions]);

  const handleImageModal = useCallback((image) => {
    setImage(image);
  }, [setImage]);

  const handleLoader = useCallback((loading = true) => {
    setIsLoading(loading);
  }, [setIsLoading]);

  // Handles selection of property when property is clicked
  const handlePropertySelect = (property, group = false) => {
    if(group){
      setSelectedPropertyGroup(property);
    } else {
      setSelectedProperty(property);
    }
  };

  const handleLogin = user => {
    setUser(user);
    setRedirect("/");
  };

  const handleLogout = () => {
    userLogout()
      .then(response => {
        if (response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'success',
            title: 'Logged out successfully',
            showConfirmButton: false,
            timer: 2000
          })
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        setUser({ logged_in: false });
        handleLoader(false);
        setRedirect('/');
      });
  };

  const handlePropertyRemove = async (id) => {
    const result = await Swal.fire({
      title: 'Remove Property Listing?',
      icon: 'question',
      // toast: true,
      text: 'Are you sure you want to permanently remove this property listing?',
      showConfirmButton: true,
      confirmButtonColor: '#a64a3d',
      showDenyButton: false,
      showCancelButton: true,
    })
    
    if (result.isConfirmed) {
      const result = await Swal.fire({
        title: 'Remove Property Listing?',
        icon: 'warning',
        // toast: true,
        text: 'Are you certain?',
        showConfirmButton: true,
        confirmButtonColor: '#a64a3d',
        showDenyButton: false,
        showCancelButton: true,
        reverseButtons: true,
      })

      if (result.isConfirmed) {
        const propertyID = parseInt(id, 10);
        handleLoader(true);
        removeProperty(propertyID)
          .then(response => {
            if (response.success) {
              handleMessagesModal(response.message);
              window.location.reload(false);
            }
            if (!response.success) {
              // handleModal(response.errors);
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
                showConfirmButton: false,
                timer: 2000
              })
            }
            handleLoader(false);
          });
      }
    }
  };

  const handlePropertyRequests = (id) => {
    const propertyID = parseInt(id, 10);
    setRedirect(`/properties/forms/${propertyID}`);
  }

  const handlePropertyEdit = (id) => {
    const propertyID = parseInt(id, 10);
    setRedirect(`/properties/edit/${propertyID}`);
  }

  const accountIcons = (navbar = false, noticeOnly = false) => {
    if(user.logged_in) {
      return (
        <Notifications
          user={user}
          notifications={notifications}
          perPage={noticePerPage}
          page={noticePage}
          maxPages={noticeMaxPages}
          setPage={setNoticePage}
          setPerPage={setNoticePerPage}
          newNotice={newNotice}
          handleLoader={handleLoader}
          setRedirect={setRedirect}
          navbar={navbar}
          noticeOnly={noticeOnly}
        />
        // <div>
        //   <button className='nav-notify-btn'>
        //     <img className='small-img' src={notificationsBtn} alt='Notifications icon' />
        //   </button>
        //   <Link ref={accountRef} id='account' to="/account" className="nav-acct-btn">
        //     <img className='small-img' src={accountBtn} alt='Account settings icon' />
        //   </Link>
        // </div> 
      );
    } else if(!noticeOnly) {
        return (
          <div className='flex-row'>
            <Link ref={loginRef} to="/login" id='login' className="nav-btn">
              {t('login')}
            </Link>
            <Link ref={signUpRef} to="/sign_up" id='sign_up' className="nav-btn">
              {t('signup')}
            </Link>
          </div> 
        );
    }
  };


  // Check if user is logged in
  useEffect(() => {
    if (sessionStorage.getItem('user')) handleLoader(true);
    userLoggedIn()
      .then(response => {
        if (response.success) setUser(response.user);
        if (!response.success) {
          // console.log(location.pathname);
          if (location.pathname === "/account") setRedirect('/login');
          if (location.pathname === "/properties/new") setRedirect('/login');
          if (location.pathname.match("/properties/edit")) setRedirect('/login');
          if (location.pathname.match("/properties/forms")) setRedirect('/login');
          if (location.pathname === "/favorites") setRedirect('/login');
          // handleModal(response.errors);
        }
        if (sessionStorage.getItem('user')) handleLoader(false);
      });
  }, [handleLoader, handleModal, location.pathname]);


  // Follow up redirect after a property is selected
  useEffect(() => {
    if (selectedProperty) {
      const { id } = selectedProperty;
      setRedirect(`/properties/${id}`);
    }
  }, [selectedProperty]);
  useEffect(() => {
    if (selectedPropertyGroup) {
      const { id } = selectedPropertyGroup;
      setRedirect(`/property_groups/${id}`);
    }
  }, [selectedPropertyGroup]);

  useEffect(() => { if(redirect!=null) { navigate(redirect); setRedirect(null); setSelectedProperty(null); } }, [redirect, navigate]);

  // useEffect(() => { console.log(windowWidth()) })

  // Fetch Notifications
  useEffect(() => {
    if (!sessionStorage.getItem('user')) return;
    if (sessionStorage.getItem('user')) handleLoader(true);
    fetchNotifications(noticePerPage, noticePage)
      .then(response => {
        if (response.success) {
          setNotifications(response.records);
          if (response.records[0]?.viewed === false) setNewNotice(true);
          setNoticeMaxPages(response.records_params.max_page || 1);
        }
        // if (!response.success) {
        //   // handleModal(response.errors);
        //   Swal.fire({
        //     position: 'top-end',
        //     toast: true,
        //     timerProgressBar: true,
        //     icon: 'error',
        //     title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
        //     showConfirmButton: false,
        //     timer: 2000
        //   })
        // }
        handleLoader(false);
      });
  }, [handleLoader, handleModal, noticePage, noticePerPage, user]);

  // open Modal to show errors
  useEffect(() => {
    setShowModal(errors.length > 0);
  }, [errors]);

  useEffect(() => {
    setShowMessageModal(messages);
  }, [messages]);

  useEffect(() => {
    setShowConfirmModal(confirmOptions.visible);
  }, [confirmOptions]);

  useEffect(() => {
    setShowImageModal(image ? true : false);
  }, [image]);

  // const location = useLocation();
  // Set CSS highlights for navmenu options based on address bar path
  useEffect(() => {
    toggleNavBar(true);
    const signUpElem = signUpRef.current;
    const loginElem = loginRef.current;
    const searchElem = searchRef.current;
    const contactElem = contactRef.current;
    const featuredElem = featuredRef.current;
    const aboutElem = aboutRef.current;
    const favoritesElem = favoritesRef.current;
    // const accountElem = accountRef.current;

    signUpElem?.classList.remove("selected-nav-btn");
    loginElem?.classList.remove("selected-nav-btn");
    searchElem?.classList.remove("selected-nav-btn");
    contactElem?.classList.remove("selected-nav-btn");
    featuredElem?.classList.remove("selected-nav-btn");
    aboutElem?.classList.remove("selected-nav-btn");
    favoritesElem?.classList.remove("selected-nav-btn");
    // accountElem?.classList.remove("selected-nav-acct-btn");

    if (location.pathname === "/sign_up") {
      signUpElem?.classList.add("selected-nav-btn");
    }
    if (location.pathname === "/login") {
      loginElem?.classList.add("selected-nav-btn");
    }
    if (location.pathname === "/search") {
      searchElem?.classList.add("selected-nav-btn");
    }
    if (location.pathname === "/contact") {
      contactElem?.classList.add("selected-nav-btn");
    }
    if (location.pathname === "/featured" || location.pathname === "/") {
      featuredElem?.classList.add("selected-nav-btn");
    }
    if (location.pathname === "/about") {
      aboutElem?.classList.add("selected-nav-btn");
    }
    if (location.pathname === "/favorites") {
      favoritesElem?.classList.add("selected-nav-btn");
    }
    // if (location.pathname === "/account") {
    //   accountElem?.classList.add("selected-nav-acct-btn");
    // }
  }, [location.pathname]);


  return (
    <div className="App">
    <header>
      <div className='bg-navbar'>
        <div id="navDropDownMenu" ref={navBar}>
          <div className='hide-xl hide-lg hide-md'>{accountIcons(true, false)}</div>
          {user?.id && <Link ref={favoritesRef} to="/favorites" id='search' className='hide-xl hide-lg hide-md'>Favorites</Link>}
          <Link ref={featuredRef} to="/featured" id='featured'>{t('featured')}</Link>
          <Link ref={searchRef} to="/search" id='search'>{t('searchAdvanced')}</Link>
          <Link ref={aboutRef} to="/about" id='about'>{t('about')}</Link>
          <Link ref={contactRef} to="/contact" id='contact'>{t('contact')}</Link>
          <button type='button' onClick={() => handleTransation()} className='hide-xl hide-lg flex-row bare-btn border-bottom pt-1 pb-05' style={{width: '100%'}}>
            <img alt='translator globe' src={Globe} style={{width: '26px'}}/> {currentLang}
          </button>
        </div>
        <nav className="container" style={{overflow: 'visible', padding: '0 12px'}}>
          {/* Mobile Screens */}
          <div className='flex-row hide-xl hide-lg hide-md'>
            <button onClick={e => toggleNavBar()} id="mobileNav"><i className="fas fa-bars text-dark"></i></button>
            <Link to="/" className="text-home mx-auto">
              <img src={logoSimple} className="App-logo" alt="logo" />
              <img src={logoText} className="App-logo" alt="logo" />
              {/* <span className='logo'>RentalFaqz.com</span> */}
            </Link>
            <div className=''>
              {accountIcons(false, true)}
            </div>
          </div>
          {/* Medium Screens */}
          <div className='hide-xl hide-lg hide-sm'>
            <div className="flex-row justify-flex-between">
                <div className='flex-row'>
                  <button type='button' onClick={e => toggleNavBar()} id="mobileNav" style={{fontSize: '24px', position: 'relative'}}><i className="fas fa-bars text-dark"></i> Menu</button>
                  <button type='button' onClick={() => handleTransation()} className='flex-row bare-btn size-1 ml-1'><img alt='translator globe' src={Globe} style={{width: '28px'}}/></button>
                </div>
                <div className='flex-row'>
                  <Link to="/" className="text-home ml-2">
                    <img src={logoSimple} className="App-logo" alt="logo" />
                    <img src={logoText} className="App-logo" alt="logo" />
                  </Link>
                </div>
                <div className=''>
                  {accountIcons()}
                </div>
              </div>
          </div>

          {/* Xtra Large and Large Screens */}
          <div className='hide-sm hide-md py'>
            <div className="flex-row justify-flex-between">
              <div className='flex-row'>
                <button type='button' onClick={e => toggleNavBar()} id="mobileNav" style={{fontSize: '24px', position: 'relative'}}><i className="fas fa-bars text-dark"></i> Menu</button>
                <button type='button' onClick={() => handleTransation()} className='flex-row bare-btn size-05 ml-1'><img alt='translator globe' src={Globe} style={{width: '24px'}}/> {currentLang}</button>
              </div>
              <div className='flex-row'>
                <Link to="/" className="text-home mr-2">
                  <img src={logoSimple} className="App-logo" alt="logo" />
                  <img src={logoText} className="App-logo" alt="logo" />
                </Link>
              </div>
              <div className=''>
                {accountIcons()}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
    <main>
      {/* Mobile Screen Fixed Nav menu Margin Boost */}
      <div className='hide-xl hide-lg hide-md' style={{width: "358px", height: "53px"}}></div>
      {/* Medium Screen Fixed Nav menu Margin Boost */}
      <div className='flex-row hide-xl hide-lg hide-sm' style={{width: "769px", height: "62px"}}></div>
      {/* Large Screen Fixed Nav menu Margin Boost */}
      <div className='flex-row hide-xl hide-md hide-sm' style={{width: "769px", height: "80px"}}></div>
      {/* Xtra Large Screen Fixed Nav menu Margin Boost */}
      <div className='flex-row hide-lg hide-md hide-sm' style={{width: "769px", height: "94px"}}></div>
      <Routes>
        <Route
          exact
          index
          element={
            <Home
              setRedirect={setRedirect}
              handlePropertyRequests={handlePropertyRequests}
              handlePropertyRemove={handlePropertyRemove}
              handleModal={handleModal}
              handleLoader={handleLoader}
              user={user}
              handlePropertySelect={handlePropertySelect}
            />}
        />
        <Route
          exact
          path="/featured"
          element={
            <Home
              setRedirect={setRedirect}
              handlePropertyRequests={handlePropertyRequests}
              handlePropertyRemove={handlePropertyRemove}
              handleModal={handleModal}
              handleLoader={handleLoader}
              user={user}
              handlePropertySelect={handlePropertySelect}
            />}
        />
        <Route
          exact
          path="/about"
          element={
            <AboutUs currentLang={currentLang} />}
        />
        <Route
          exact
          path="/contact"
          element={
            <ContactUs currentLang={currentLang} />}
        />
        <Route
          exact
          path="/sign_up"
          element={<Register handleModal={handleModal} handleLoader={handleLoader} handleMessagesModal={handleMessagesModal} />}
        />
        <Route
          exact
          path="/login"
          element={
            <Login
              handleModal={handleModal}
              handleMessagesModal={handleMessagesModal}
              handleLoader={handleLoader}
              handleLogin={handleLogin}
            />}
        />
        <Route
          exact
          path="/forgot_password"
          element={<ForgotPassword handleModal={handleModal} handleLoader={handleLoader} />}
        />
        <Route
          exact
          path="/account"
          element={
            <ProtectedRoute user={user}>
              <Account
                handlePropertyRequests={handlePropertyRequests}
                handlePropertyRemove={handlePropertyRemove}
                handleMessagesModal={handleMessagesModal}
                handleConfirmModal={handleConfirmModal}
                handleModal={handleModal}
                handleLoader={handleLoader}
                handleLogout={handleLogout}
                handlePropertySelect={handlePropertySelect}
                handlePropertyEdit={handlePropertyEdit}
                setRedirect={setRedirect}
                user={user}
              />
            </ProtectedRoute>}
        />
        <Route
          exact
          path="/search"
          element={
            <SearchForProperty
              handlePropertyRequests={handlePropertyRequests}
              handlePropertyRemove={handlePropertyRemove}
              handleModal={handleModal}
              handleLoader={handleLoader}
              user={user}
              handlePropertySelect={handlePropertySelect}
              handlePropertyEdit={handlePropertyEdit}
            />}
        />
        <Route
          exact
          path="/favorites"
          element={
            <ProtectedRoute user={user}>
              <PropertyFavorites
                handlePropertyRequests={handlePropertyRequests}
                handlePropertyRemove={handlePropertyRemove}
                handleModal={handleModal}
                handleLoader={handleLoader}
                user={user}
                handlePropertySelect={handlePropertySelect}
                handlePropertyEdit={handlePropertyEdit}
              />
            </ProtectedRoute>}
        />
        <Route
          exact
          path="/properties/:id"
          element={
            <PropertyDisplay
              user={user}
              setRedirect={setRedirect}
              handlePropertyRemove={handlePropertyRemove}
              handlePropertyRequests={handlePropertyRequests}
              handleLoader={handleLoader}
              handleModal={handleModal}
              handleMessagesModal={handleMessagesModal}
              handleImageModal={handleImageModal}
              handleConfirmModal={handleConfirmModal}
              currentLang={currentLang}
            />}
        />
        <Route
          exact
          path="/properties/new"
          element={
            <ProtectedRoute user={user}>
              <NewProperty
                user={user}
                setRedirect={setRedirect}
                handleMessagesModal={handleMessagesModal} 
                handleLoader={handleLoader}
                handleModal={handleModal}
                handleImageModal={handleImageModal}
              />
            </ProtectedRoute>}
        />
        <Route
          exact
          path="/properties/edit/:id"
          element={
            <ProtectedRoute user={user}>
              <EditProperty
                user={user}
                setRedirect={setRedirect}
                handleMessagesModal={handleMessagesModal} 
                handleLoader={handleLoader}
                handleModal={handleModal}
                handleImageModal={handleImageModal}
              />
            </ProtectedRoute>}
        />
        <Route
          exact
          path="/property_groups/:id"
          element={
            <PropertyGroupDisplay
              user={user}
              setRedirect={setRedirect}
              handlePropertyRemove={handlePropertyRemove}
              handlePropertyRequests={handlePropertyRequests}
              handleLoader={handleLoader}
              handleModal={handleModal}
              handleMessagesModal={handleMessagesModal}
              handleImageModal={handleImageModal}
              handleConfirmModal={handleConfirmModal}
              currentLang={currentLang} 
            />}
        />
        <Route
          exact
          path="/properties/forms/:id"
          element={
            <ProtectedRoute user={user}>
              <PropertyForums
                user={user}
                setRedirect={setRedirect}
                handleImageModal={handleImageModal} 
                handleMessagesModal={handleMessagesModal} 
                handleLoader={handleLoader}
                handleModal={handleModal}
              />
            </ProtectedRoute>}
        />
        <Route
          exact
          path="/properties/forms/:id/comment/:comment_id"
          element={
            <ProtectedRoute user={user}>
              <PropertyForumDisplay
                user={user}
                setRedirect={setRedirect}
                handleImageModal={handleImageModal} 
                handleMessagesModal={handleMessagesModal} 
                handleLoader={handleLoader}
                handleModal={handleModal}
              />
            </ProtectedRoute>}
        />
      </Routes>
    </main>
    <footer>
      <div style={{backgroundColor: 'rgb(232 232 232)'}}>
        <div className='text-right size-0 container'>
          <span>© RentalFaqz.com 2023</span>
        </div>
      </div>
    </footer>
    {showImageModal && <ImageModal image={image} handleImageModal={handleImageModal} />}
    {showMessageModal && <NoticeModal messages={messages} handleMessagesModal={handleMessagesModal} />}
    {showConfirmModal && <ConfirmModal options={confirmOptions} handleConfirmModal={handleConfirmModal} />}
    {showModal && <Modal errors={errors} handleModal={handleModal} />}
    {isLoading && <Loader />}
  </div>
  );
}

export default App;
