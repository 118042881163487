import React, { useState } from 'react';
import propTypes from 'prop-types';
import { userRegister } from '../../misc/apiRequests';
import { enforceFormat } from '../../misc/misc';
import ConfirmPage from '../../presentational/users/confirmPage';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { countries } from '../../misc/jsonData/islandData';
import SignUpPhoto from '../../../assets/images/signup.jpg';
import Landlord from '../../../assets/images/landlord.svg';
import Citizen from '../../../assets/images/citizen.svg';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const Register = ({ handleModal, handleLoader, handleMessagesModal }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [backupEmail, setBackupEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  // Profile Info
  const [phoneNumb, setPhoneNumb] = useState('');
  const [mobileNumb, setMobileNumb] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sex, setSex] = useState('male');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [countryOfBirth, setCountryOfBirth] = useState('');
  const [residingCountry, setResidingCountry] = useState('');
  const [nib, setNib] = useState('');
  const [govIDUpload, setGovIDUpload] = useState(null);
  const [photoUpload, setPhotoUpload] = useState(null);
  const [jobLetterUpload, setJobLetterUpload] = useState(null);
  const [relationshipStatus, setRelationshipStatus] = useState('single');
  const [occupation, setOccupation] = useState('');
  const [employer, setEmployer] = useState('');
  const [employmentLength, setEmploymentLength] = useState('');
  const [workPhone, setWorkPhone] = useState('');
  const [annualIncome, setAnnualIncome] = useState('less than 10,000');
  const [withSpouse, setWithSpouse] = useState(false);
  const [withChildren, setWithChildren] = useState(false);
  const [withBaby, setWithBaby] = useState(false);
  const [renter, setRenter] = useState(false);
  const [userTypeSelected, setUserTypeSelected] = useState(false);
  // Banking info
  // const [bankName, setBankName] = useState('');
  // const [bankBranch, setBankBranch] = useState('');
  // const [bankAcctNumb, setBankAcctNumb] = useState('');
  // const [bankTransit, setBankTransit] = useState('');

  const [declare, setDeclare] = useState(false);

  const [message, setMessage] = useState('');
  const [userCreds, setUserCreds] = useState({});
  const [emailConfirm, setEmailConfirm] = useState(false);

  // const dobInput = useRef(null);

  // Populate Islands and Settlements
  const populateCountries = () => countries.map((data, index) => (
    <option className='size-3' key={index} value={data.name}>{data.name}</option>
  ));

  // Ensures that the profile image uploaded doesn't go too far over 1 megabyte
  const handleCheckFileSize = (e, fileType = 'photo') => {
    const elem = e.target;
    if (elem.files[0].size > 5048576) {
      elem.value = '';
      if(fileType === 'photo') handleMessagesModal('Photo upload - File size too big (max size allowed: 5 megabyte)');
      if(fileType === 'govID') handleMessagesModal('Government ID photo upload - File size too big (max size allowed: 5 megabyte)');
      if(fileType === 'jobLetter') handleMessagesModal('Job Letter  upload - File size too big (max size allowed: 5 megabyte)');
    } else { 
      if(fileType === 'photo') { setPhotoUpload(elem.files[0]); }
      if(fileType === 'govID') { setGovIDUpload(elem.files[0]); }
      if(fileType === 'jobLetter') { setJobLetterUpload(elem.files[0]); }
    }
  };

  const handleSelectUserType = (user = 'renter') => {
    if(user === 'renter'){ setRenter(true);}
    else { setRenter(false);}
    setUserTypeSelected(!userTypeSelected);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      return handleModal([t('f_passwordMatch')]);
    }

    const user = new FormData();
    user.append('user[email]', email.trim());
    user.append('user[email_backup]', backupEmail.trim());
    user.append('user[password]', password);
    user.append('user[password_confirmation]', passwordConfirm);

    photoUpload && user.append('user[profile_info][photo_upload]', photoUpload);
    govIDUpload && user.append('user[profile_info][gov_id_upload]', govIDUpload);
    jobLetterUpload && user.append('user[profile_info][job_letter_upload]', jobLetterUpload);
    user.append('user[profile_info][phone_numb]', phoneNumb.trim());
    user.append('user[profile_info][mobile_numb]', mobileNumb.trim());
    user.append('user[profile_info][work_phone_numb]', workPhone.trim());
    user.append('user[profile_info][first_name]', firstName.trim());
    user.append('user[profile_info][middle_name]', middleName.trim());
    user.append('user[profile_info][last_name]', lastName.trim());
    user.append('user[profile_info][sex]', sex);
    user.append('user[profile_info][date_of_birth]', dateOfBirth);
    user.append('user[profile_info][country_of_birth]', countryOfBirth);
    user.append('user[profile_info][residing_country]', residingCountry);
    user.append('user[profile_info][nib]', nib);
    user.append('user[profile_info][relationship_status]', relationshipStatus);
    user.append('user[profile_info][occupation]', occupation);
    user.append('user[profile_info][employer]', employer);
    user.append('user[profile_info][employment_length]', employmentLength);
    user.append('user[profile_info][annual_income]', annualIncome);
    user.append('user[profile_info][with_spouse]', JSON.stringify(Boolean(withSpouse)));
    user.append('user[profile_info][with_children]', JSON.stringify(Boolean(withChildren)));
    user.append('user[profile_info][with_baby]', JSON.stringify(Boolean(withBaby)));
    user.append('user[profile_info][renter]', JSON.stringify(Boolean(renter)));
    user.append('user[profile_info][declare]', JSON.stringify(Boolean(declare)));
    // Banking info
    // user.append('user[profile_info][bank_name]', bankName);
    // user.append('user[profile_info][bank_acct_numb]', bankAcctNumb);
    // user.append('user[profile_info][bank_branch]', bankBranch);
    // user.append('user[profile_info][bank_transit_numb]', bankTransit);


    // const profile_info = {
    //   phone_numb: phoneNumb.trim(),
    //   mobile_numb: mobileNumb.trim(),
    //   first_name: firstName.trim(),
    //   middle_name: middleName.trim(),
    //   last_name: lastName.trim(),
    //   sex: sex,
    //   date_of_birth: dateOfBirth,
    //   nib: nib,
    //   gov_id_upload: govIDUpload,
    //   photo_upload: photoUpload,
    //   relationship_status: relationshipStatus,
    //   occupation: occupation,
    //   employer: employer,
    //   work_phone: workPhone,
    //   annual_income: annualIncome,
    //   with_spouse: withSpouse,
    //   with_children: withChildren,
    //   with_baby: withBaby,
    //   renter: renter,
    //   declare: declare
    // };

    // const user = {
    //   email: email.trim(),
    //   email_backup: backupEmail.trim(),
    //   password,
    //   password_confirmation: passwordConfirm,
    //   profile_info
    // };

    setUserCreds({ first_name: firstName, email: email.trim() });

    handleLoader(true);
    userRegister(user)
      .then(response => {
        if (response.success) { setMessage(response.message); setEmailConfirm(true); }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  // Max Date of Birth Range
  // const maxDobRange = () => {
  //   const dobElem = dobInput?.current;

  //   let today = new Date();
  //   let dd = today.getDate();
  //   let mm = today.getMonth()+1; //January is 0!
  //   const yyyy = today.getFullYear()-18;
  //   if(dd < 10){
  //           dd='0'+dd
  //       } 
  //       if(mm<10){
  //           mm='0'+mm
  //       } 

  //   today = yyyy+'-'+mm+'-'+dd;
  //   dobElem?.setAttribute("max", today);
  // };

  let today = new Date();
  const dobMax = `${today.getFullYear()-18}-12-31`;

  // useEffect(() => {
  //   maxDobRange();
  // }, [dobInput]);

  return emailConfirm
    ? <ConfirmPage user={userCreds} />
    : (
      <div id="LoginPage" className="pt-1">
        <div className="container">
          <div className='register-flex'>
            <div className='login-img'>
              <img src={SignUpPhoto} alt='by Connor James on Unsplash'/>
              <div className='overlay-bg-dark' />
              <div className='register-text'>
                <h2>{t('joinCommunity')}</h2>
                <span>{t('fullAccess')}</span>
              </div>
            </div>
            <div className='ml-1-lg overflow-scroll pb-1 register-form'>

              {/* Choose Landlord or Tenant */}
              {(userTypeSelected === false) && (
              <div className='p-1-xl'>
                <h3 className='text-center mb-3'>{t('siteUsage')}</h3>
                {/* <h2 className='text-center mb-3' style={{textTransform: 'none'}}>As a...</h2> */}
                <div className='register-as'>
                  <button type='button' onClick={() => handleSelectUserType()}>
                    <img src={Landlord} alt='landlord button'/>
                  </button>
                  <h3>{t('landLord')}</h3>
                  <span className='form-sub-text'>{t('landLordDesc')}</span>
                </div>
                <h3 className='text-center mt-2 mb-2'>{t('or')}</h3>
                <div className='register-as'>
                  <button type='button' onClick={() => handleSelectUserType('citizen')}>
                    <img src={Citizen} alt='tenant button'/>
                  </button>
                  <h3>{t('tenant')}</h3>
                  <span className='form-sub-text'>{t('tenantDesc')}</span>
                </div>
              </div>
              )}

              {/* Full form for user signup */}
              {(userTypeSelected === true) && (
              <form className="login-form" onSubmit={handleSubmit} encType="multipart/form-data">
                {(renter === true) && (
                <div className='register-as mb-2'>
                  <button type='button' onClick={() => handleSelectUserType()}>
                    <img src={Landlord} alt='landlord button'/>
                  </button>
                  <h3>{t('landLord')}</h3>
                  <span className='form-sub-text'>{t('landLordDesc')}</span>
                </div>
                )}
                {(renter === false) && (
                <div className='register-as mb-2'>
                  <button type='button' onClick={() => handleSelectUserType()}>
                    <img src={Citizen} alt='tenant button'/>
                  </button>
                  <h3>{t('tenant')}</h3>
                  <span className='form-sub-text'>{t('tenantDesc')}</span>
                </div>
                )}

                <h3 className="pb-1">{t('personInfo')}</h3>
                <span className='form-sub-text pb-1 text-grey'>* {t('requiredFields')}</span>
                <span className='form-sub-text'>{t('f_fName')}</span>
                <input
                  type="text"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  minLength="3"
                  required
                />
                <span className='form-sub-text'>{t('f_mName')}</span>
                <input
                  type="text"
                  value={middleName}
                  onChange={e => setMiddleName(e.target.value)}
                  minLength="3"
                />
                <span className='form-sub-text'>{t('f_lName')}</span>
                <input
                  type="text"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  minLength="3"
                  required
                />
                <span className='form-sub-text'>{t('f_sex')}</span>
                <select required name="sex" id="sex" value={sex} onChange={e => setSex(e.target.value)}>
                  <option value="male">{t('f_opt_male')}</option>
                  <option value="female">{t('f_opt_female')}</option>
                </select>
                <span className='form-sub-text'>{t('f_dob')}</span>
                <span className='form-sub-text text-grey'>* {t('f_dobDesc')}</span>
                <input
                  // ref={dobInput}
                  type="date"
                  value={dateOfBirth}
                  onChange={e => setDateOfBirth(e.target.value)}
                  max={dobMax}
                  required
                />
                <span className='form-sub-text'>{t('f_cob')}</span>
                <select required name="birth_country" id="birth_country" value={countryOfBirth} onChange={e => setCountryOfBirth(e.target.value)}>
                  <option value=""></option>
                  {populateCountries()}
                </select>
                <span className='form-sub-text'>{t('f_cor')}</span>
                <select required name="country_residence" id="country_residence" value={residingCountry} onChange={e => setResidingCountry(e.target.value)}>
                  <option value=""></option>
                  {populateCountries()}
                </select>
                <span className='form-sub-text'>{t('f_nib')}</span>
                <input
                  type="text"
                  value={nib}
                  onChange={e => setNib(e.target.value)}
                  onKeyDown={e => enforceFormat(e)}
                  minLength="5"
                  maxLength="16"
                />
                <span className='form-sub-text'>{t('f_govID')}</span>
                <input
                  required
                  type="file"
                  name="gov_id_upload"
                  alt='Government ID upload'
                  accept="image/png, image/jpeg, image/jpg"
                  // value={govIDUpload}
                  onChange={e => handleCheckFileSize(e, 'govID')}
                />
                <span className='form-sub-text'>{t('f_photo')}</span>
                <input
                  type="file"
                  alt='Profile photo upload'
                  name="photo_upload"
                  accept="image/png, image/jpeg, image/jpg"
                  // value={photoUpload}
                  onChange={e => handleCheckFileSize(e, 'photo')}
                />

                <h3 className="pb-1 mt-2">{t('f_contact')}</h3>
                <span className='form-sub-text'>{t('f_phone')} #1</span>
                <PhoneInput
                  placeholder="Enter home/mobile phone number"
                  value={phoneNumb}
                  onChange={setPhoneNumb}
                  // defaultCountry={"BS"}
                  limitMaxLength={true}
                  rules={{ required: true }}
                />
                <span className='form-sub-text'>{t('f_phone')} #2</span>
                <PhoneInput
                  placeholder="Enter mobile phone number"
                  value={mobileNumb}
                  onChange={setMobileNumb}
                  // defaultCountry={"BS"}
                  limitMaxLength={true}
                />

                {/* Landlord Banking Information */}
                {/* {renter === true && (
                  <div className='flex-col'>
                    <h3 className="pb-1 mt-2">{t('f_bank')}</h3>
                    <span className='form-sub-text'>{t('f_bankName')}</span>
                    <select name="bank_name" id="bank_name" value={bankName} onChange={e => setBankName(e.target.value)}>
                      <option value=""></option>
                      <option value="scotiabank">Scotiabank</option>
                      <option value="cibc">CIBC First Caribbean</option>
                      <option value="bob">Bank of the Bahamas</option>
                      <option value="fidelity">Fidelity Bank</option>
                      <option value="commonwealth">Commonwealth Bank</option>
                      <option value="rbc">Royal Bank of Canada</option>
                    </select>
                    <span className='form-sub-text'>{t('f_bankBranch')}</span>
                    <input
                      type="text"
                      value={bankBranch}
                      onChange={e => setBankBranch(e.target.value)}
                    />
                    <span className='form-sub-text'>{t('f_bankTransit')} #</span>
                    <input
                      type="number"
                      value={bankTransit}
                      onChange={e => setBankTransit(e.target.value)}
                    />
                    <span className='form-sub-text'>{t('f_bankAccount')} #</span>
                    <input
                      type="number"
                      value={bankAcctNumb}
                      onChange={e => setBankAcctNumb(e.target.value)}
                    />
                  </div>
                )} */}

                {/* Tenant Income Information */}
                {renter === false && (
                  <div className='flex-col'>
                    <h3 className="pb-1 mt-2">{t('f_family')}</h3>
                    <span className='form-sub-text'>{t('f_relations')}</span>
                    <select required name="relationshipStatus" id="relationshipStatus" value={relationshipStatus} onChange={e => setRelationshipStatus(e.target.value)}>
                      <option value="single">{t('f_opt_single')}</option>
                      <option value="married">{t('f_opt_married')}</option>
                      <option value="divorced">{t('f_opt_divorced')}</option>
                      <option value="relationship">{t('f_opt_relationship')}</option>
                    </select>
                    <span className='form-sub-text'>{t('f_spouse')}</span>
                    <input
                      type="checkbox"
                      value={withSpouse}
                      onChange={e => setWithSpouse(e.target.value)}
                    />
                    <span className='form-sub-text'>{t('f_haveChildren')}</span>
                    <input
                      type="checkbox"
                      value={withChildren}
                      onChange={e => setWithChildren(e.target.value)}
                    />
                    <span className='form-sub-text'>{t('f_haveBaby')}</span>
                    <input
                      type="checkbox"
                      value={withBaby}
                      onChange={e => setWithBaby(e.target.value)}
                    />

                    <h3 className="pb-1 mt-2">{t('f_income')}</h3>
                    <span className='form-sub-text'>{t('f_occupation')}</span>
                    <select name="occupation" id="occupation" onChange={e => setOccupation(e.target.value)}>
                      <option value=""></option>
                      <option className='size-3' value="Agriculture, Food &amp; Natural Resources">{t('f_opt_agriculture')}</option>
                      <option className='size-3' value="Arts, Audio/Video Technology &amp; Communications">{t('f_opt_art')}</option>
                      <option className='size-3' value="Education &amp; training">{t('f_opt_education')}</option>
                      <option className='size-3' value="Government &amp; Public Administration">{t('f_opt_government')}</option>
                      <option className='size-3' value="Hospitality &amp; Tourism">{t('f_opt_hospitality')}</option>
                      <option className='size-3' value="Information technology">{t('f_opt_IT')}</option>
                      <option className='size-3' value="Manufacturing">{t('f_opt_manufacture')}</option>
                      <option className='size-3' value="Science, technology, Engineering &amp; Mathematics">{t('f_opt_math')}</option>
                      <option className='size-3' value="Architecture &amp; Construction">{t('f_opt_construction')}</option>
                      <option className='size-3' value="Business Management &amp; Administration">{t('f_opt_business')}</option>
                      <option className='size-3' value="Finance">{t('f_opt_finance')}</option>
                      <option className='size-3' value="Health Science">{t('f_opt_health')}</option>
                      <option className='size-3' value="Human Services">{t('f_opt_human')}</option>
                      <option className='size-3' value="Law, Public Safety, Corrections &amp; Security">{t('f_opt_law')}</option>
                      <option className='size-3' value="Marketing, Sales &amp; Service">{t('f_opt_marketing')}</option>
                      <option className='size-3' value="Transportation, Distribution &amp; Logistics">{t('f_opt_transportation')}</option>
                      <option className='size-3' value="Other">{t('f_opt_other')}</option>
                    </select>
                    <span className='form-sub-text'>{t('f_employer')}</span>
                    <input
                      type="text"
                      value={employer}
                      onChange={e => setEmployer(e.target.value)}
                      minLength="3"
                      required
                    />
                    <span className='form-sub-text'>{t('f_employmentLength')}</span>
                    <input
                      type="number"
                      value={employmentLength}
                      onChange={e => setEmploymentLength(e.target.value)}
                      required
                    />
                    <span className='form-sub-text'>{t('f_jobLetter')}</span>
                    <input
                      required
                      type="file"
                      name="job_letter_upload"
                      alt='Job Letter upload'
                      accept="image/png, image/jpeg, image/jpg"
                      // value={govIDUpload}
                      onChange={e => handleCheckFileSize(e, 'jobLetter')}
                    />
                    <span className='form-sub-text'>{t('f_workContact')}</span>
                    <PhoneInput
                      placeholder="Enter work phone number"
                      value={workPhone}
                      onChange={setWorkPhone}
                      // defaultCountry={"BS"}
                      limitMaxLength={true}
                    />
                    <span className='form-sub-text'>{t('f_annualIncome')} $BSD</span>
                    <select name="annual_income" id="annual_income" onChange={e => setAnnualIncome(e.target.value)} required>
                      <option value="less than 10,000">{t('f_lessThan')} $10,000</option>
                      <option value="10,000 - 19,000">$10,000 - $19,000</option>
                      <option value="20,000 - 29,000">$20,000 - $29,000</option>
                      <option value="30,000 - 39,000">$30,000 - $39,000</option>
                      <option value="40,000 - 49,000">$40,000 - $49,000</option>
                      <option value="50,000 - 59,000">$50,000 - $59,000</option>
                      <option value="60,000 - 69,000">$60,000 - $69,000</option>
                      <option value="70,000 - 79,000">$70,000 - $79,000</option>
                      <option value="80,000 and higher">$80,000 {t('f_higherThan')}</option>
                    </select>
                  </div>
                )}

                <h3 className="pb-1 mt-2">{t('f_account')}</h3>
                <span className='form-sub-text'>{t('f_email')}</span>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  minLength="3"
                  required
                />
                <span className='form-sub-text'>{t('f_backupEmail')}</span>
                <input
                  type="email"
                  value={backupEmail}
                  onChange={e => setBackupEmail(e.target.value)}
                  minLength="3"
                />
                <span className='form-sub-text'>{t('f_password')}</span>
                <input
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                <span className='form-sub-text'>{t('f_passwordConfirm')}</span>
                <input
                  type="password"
                  value={passwordConfirm}
                  onChange={e => setPasswordConfirm(e.target.value)}
                  required
                />
                <span className='form-sub-text'>{t('f_declaration')}</span>
                <input
                  type="checkbox"
                  value={declare}
                  onChange={e => setDeclare(e.target.value)}
                  required
                />
                <button type="submit">{t('signup')}</button>
                <p className='size-05'>
                  {t('f_terms')} RentalFaqz.com <a href='/about?legal_terms=terms_and_conditions' target="_blank">{t('f_termsUse')}</a>
                </p>
              </form>)}
            </div>
          </div>
          <h6 className="text-center p-1">{message}</h6>
        </div>
      </div>
    );
};

Register.propTypes = {
  handleModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
};

export default Register;