/* eslint-disable no-unused-vars */

import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PropertyForumDisplayThumb = ({ comment, handleSelectComment }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const { id, comment_id, comment_type, title,
          body, created_at, updated_at,
          images, user } = comment;
  // const dateEnd = created_at.indexOf('T');
  // const shortDate = created_at.substring(0, dateEnd);
  const bodyLength = body.length;
  const titleLength = title.length;

  return (
    <button key={id} onClick={() => handleSelectComment(comment)} className='forum-thread mb-1'>
      <div className=''>
        <div className='flex-row'>
          <h2>
            {title.slice(0, 26)}
            {titleLength > 26 && '...'}
          </h2>
          <img alt='User Avatar' src={user?.photo} />
        </div>
        <div className='flex-col'>
          <span className='text-camel text-bold size-4'>{user.profile?.first_name} {user.profile?.last_name}</span>
          <span className='size-01'>{t('f_posted')}: {created_at} {created_at !== updated_at && `, ${t('f_edited')}: ${updated_at}`}</span>
        </div>
        <p className='size-3'>
          {body.slice(0, 120)}
          {bodyLength > 120 && '...'}
        </p>
      </div>
    </button>
  );
};

PropertyForumDisplayThumb.propTypes = {
  comment: propTypes.instanceOf(Object).isRequired,
  handleSelectComment: propTypes.func.isRequired,
};

export default PropertyForumDisplayThumb;
