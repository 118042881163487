import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NoticeModal = ({ messages, handleMessagesModal }) => {
  const { t } = useTranslation();

  // Returns JSX relative to whether the messages are given in array or string format
  const showMessages = () => {
    if (Array.isArray(messages)) {
      return messages.map((msg, index) => (
        <div key={index} className="message pb-1">{msg}</div>
      ));
    }

    return (<div className="message">{messages}</div>);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="container-md mb-1">
          <div className="messages-container">
            {showMessages()}
          </div>
        </div>
        <button type="button" className="modal-btn" onClick={() => handleMessagesModal()}>{t('f_ok')}</button>
      </div>
    </div>
  );
};

NoticeModal.propTypes = {
  messages: propTypes.oneOfType([
    propTypes.instanceOf(Array),
    propTypes.string,
  ]),
  handleMessagesModal: propTypes.func.isRequired,
};

export default NoticeModal;
