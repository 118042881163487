/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PaymentModal = ({ info, handleModal, handleLoader, handlePaymentModal, isModal = true, days = 1 }) => {
  const { t } = useTranslation();

  const { id, payment_amount, fee, desc, property } = info;

  const [chargedAmount, setChargedAmount] = useState(fee * days);
  const [cardHolder, setCardHolder] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardMonth, setCardMonth] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [cardExpiry, setCardExpiry] = useState('');
  const [cardCVC, setCardCVC] = useState('');


  const focusSibling = function(target, direction, callback) {
    const nextTarget = target[direction];
    nextTarget && nextTarget.focus();
    // if callback is supplied we return the sibling target which has focus
    callback && callback(nextTarget);
  }

  const checkMonthValue = e => {
    const value = e.target.value.toString();
    // adds 0 to month user input like 9 -> 09
    if (value.length === 1 && value > 1) {
      e.target.value = "0" + value;
    }
    // bounds
    if (value === "00") {
      e.target.value = "01";
    } else if (value > 12) {
      e.target.value = "12";
    }
    // if we have a filled input we jump to the year input
    2 <= e.target.value.length && focusSibling(e.target, "nextElementSibling");
    // e.stopImmediatePropagation();
  
    setCardMonth(e.target.value);
  };

  const checkYearValue = e => {
    // if the year is empty jump to the month input
    if (e.key === "Backspace" && e.target.selectionStart === 0) {
      focusSibling(e.target, "previousElementSibling");
      // e.stopImmediatePropagation();
    }
  };

  const inputMatchesPattern = function(e) {
    const { 
      value, 
      selectionStart, 
      selectionEnd, 
      pattern 
    } = e.target;
    
    const character = String.fromCharCode(e.which);
    const proposedEntry = value.slice(0, selectionStart) + character + value.slice(selectionEnd);
    const match = proposedEntry.match(pattern);
    
    return e.metaKey || // cmd/ctrl
      e.which <= 0 || // arrow keys
      // eslint-disable-next-line no-mixed-operators
      e.which === 8 || // delete key
      // eslint-disable-next-line no-mixed-operators
      match && match["0"] === match.input; // pattern regex isMatch - workaround for passing [0-9]* into RegExp
  };
  
  document.querySelectorAll('input[data-pattern-validate]').forEach(el => el.addEventListener('keypress', e => {
    if (!inputMatchesPattern(e)) {
      return e.preventDefault();
    }
  }));

  const handleSubmit = e => {
    e.preventDefault();

    const payment = new FormData();
    payment.append('payment[amount]', chargedAmount);
    payment.append('payment[card_holder]', cardHolder);
    payment.append('payment[card_number]', cardNumber);
    payment.append('payment[card_expiry]', cardExpiry);
    payment.append('payment[card_cvc]', cardCVC);

    // handleLoader(true);
    // submitPayment(property)
    //   .then(response => {
    //     if (response.success) { handleMessagesModal(response.message); }
    //     if (!response.success) handleModal(response.errors);
    //     handleLoader(false);
    //   });

    return null;
  };
  
  return isModal ? (
    <div className="modal">
      <div className="modal-content-payment">
        <div className="container-md">
          <div className="">
            <h2 className='pb-1'>{t('f_payment')}</h2>
            <form className="payment-form" onSubmit={handleSubmit} encType="multipart/form-data">
              <div className='flex-col'>
              <span className='form-sub-text'>{t('f_cardHolder')}</span>
              <input
                type="text"
                value={cardHolder}
                onChange={e => setCardHolder(e.target.value)}
                minLength="8"
                required
              />
              <span className='form-sub-text pt-1'>{t('f_paymentAmount')}</span>
              <span>${chargedAmount}</span>
              <span className='form-sub-text pt-1'>{t('f_cardNumber')}</span>
              <input
                type="text"
                value={cardNumber}
                onChange={e => setCardNumber(e.target.value)}
                minLength="8"
                required
              />
              <div className='flex-row ml-auto pt-1 pb-1'>
                <div className='flex-col mr-1'>
                  <span className='form-sub-text'>{t('f_cardExpiry')}</span>
                  <div>
                  <input
                    type="text"
                    name="month"
                    placeholder="MM"
                    maxLength="2"
                    size="2"
                    pattern="[0-9]*"
                    inputMode="numerical"
                    data-pattern-validate
                    onChange={e => checkMonthValue(e)}
                    required
                  />
                  <span style={{fontSize: '0.4em'}}>{" / "}</span>
                  <input
                    type="text"
                    name="year"
                    placeholder="YY"
                    maxLength="2"
                    size="2"
                    pattern="[0-9]*"
                    inputMode="numerical"
                    data-pattern-validate
                    onKeyDown={e => checkYearValue(e)}
                    onChange={e => setCardYear(e)}
                    required
                  />
                  </div>
                </div>
                <div className='flex-col'>
                  <span className='form-sub-text'>{t('f_cardCVC')}</span>
                  <input
                    type="text"
                    value={cardCVC}
                    onChange={e => setCardCVC(e.target.value)}
                    minLength="3"
                    required
                  />
                </div>
              </div>
              <span className='form-sub-text'>We accept: [card types accepted image list]</span>
              </div>
              <div className='flex-row'>
              <button type="button" className="modal-btn ml-auto grey-btn" onClick={() => handlePaymentModal(true)}>{t('f_cancel')}</button>
              <button type="submit" className="modal-btn" onClick={() => handlePaymentModal()}>{t('f_pay')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="content-payment">
      <h2 className='pb-1'>{t('f_payment')}</h2>
      <form className="payment-form" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className='flex-col'>
        <span className='form-sub-text'>{t('f_cardHolder')}</span>
        <input
          type="text"
          value={cardHolder}
          onChange={e => setCardHolder(e.target.value)}
          minLength="8"
          required
        />
        <span className='form-sub-text pt-1'>{t('f_paymentAmount')}</span>
        <span>${chargedAmount}</span>
        <span className='form-sub-text pt-1'>{t('f_cardNumber')}</span>
        <input
          type="text"
          value={cardNumber}
          onChange={e => setCardNumber(e.target.value)}
          minLength="8"
          required
        />
        <div className='flex-row ml-auto pt-1 pb-1'>
          <div className='flex-col mr-1'>
            <span className='form-sub-text'>{t('f_cardExpiry')}</span>
            <div>
            <input
              type="text"
              name="month"
              placeholder="MM"
              maxLength="2"
              size="2"
              pattern="[0-9]*"
              inputMode="numerical"
              data-pattern-validate
              onChange={e => checkMonthValue(e)}
              required
            />
            <span style={{fontSize: '0.4em'}}>{" / "}</span>
            <input
              type="text"
              name="year"
              placeholder="YY"
              maxLength="2"
              size="2"
              pattern="[0-9]*"
              inputMode="numerical"
              data-pattern-validate
              onKeyDown={e => checkYearValue(e)}
              onChange={e => setCardYear(e)}
              required
            />
            </div>
          </div>
          <div className='flex-col'>
            <span className='form-sub-text'>{t('f_cardCVC')}</span>
            <input
              type="text"
              value={cardCVC}
              onChange={e => setCardCVC(e.target.value)}
              minLength="3"
              required
            />
          </div>
        </div>
        <span className='form-sub-text'>We accept: [card types accepted image list]</span>
        </div>
        <div className='flex-row'>
          <button type="button" className="mr-auto grey-btn" onClick={() => handlePaymentModal(true)}>{t('f_cancel')}</button>
          <button type="submit" className="" onClick={() => handlePaymentModal()}>{t('f_pay')}</button>
        </div>
      </form>
    </div>
  );
};

PaymentModal.propTypes = {
  info: propTypes.instanceOf(Object).isRequired,
  handleModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handlePaymentModal: propTypes.func.isRequired,
};

export default PaymentModal;
