/* eslint-disable no-unused-vars */

import React, { useEffect, useRef } from 'react';
import propTypes, { number } from 'prop-types';
import { useTranslation } from 'react-i18next';

const PropertyTransactionsDisplay = ({ propertyInfo, handlePropertySelect }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase


  const initialDeposit = (depositExpected) => {
    let deposit = parseInt(depositExpected)
    deposit = deposit ? deposit : '0'
    return deposit;
  };

  const totalFee = (deposit, fee) => {
    return parseInt(initialDeposit(deposit)) + parseInt(fee);
  };

  const paymentTypeOwned = () => (
    <button type='button' className='not-button' onClick={() => handlePropertySelect(propertyInfo)}>
    <div>
      <h3 className='text-camel'>
        {`${propertyInfo?.property_group?.street_address || propertyInfo.street_address}, 
        ${propertyInfo?.property_group?.island || propertyInfo.island}, 
        ${propertyInfo?.property_group?.settlement || propertyInfo.settlement}`}
      </h3>
      <h3 className='text-camel'>{propertyInfo?.property_group && propertyInfo?.property_unit}</h3>
      <div className='p-05 transact-table'>
        <div className='text-camel'>
          <span>{t('f_propertyType')}: </span>
          <span>{propertyInfo.property_type || 'Complex'}</span>
        </div>
        {/* <div>
          <span>{t('f_initialDeposit')}: </span>
          <span>${numberWithCommas(initialDeposit(propertyInfo.deposit_expected))}</span>
        </div>
        <div>
          <span>{t('f_monthlyFee')}: </span>
          <span>${numberWithCommas(propertyInfo.fee)}</span>
        </div>
        <div>
          <span>{t('f_totalFee')}: </span>
          <span>${numberWithCommas(totalFee(propertyInfo.deposit_expected, propertyInfo.fee))}</span>
        </div>
        <div className='text-camel'>
          <span>{t('f_frequency')}: </span>
          <span>{propertyInfo.payment_frequency}</span>
        </div> */}
      </div>
    </div>
    </button>
  );


  return (
    <div>
      <div className='payment-box pt-1' style={{backgroundColor: 'transparent', border: '1px solid var(--navButtonGold)', maxWidth: '460px', minHeight: '210px'}}>
        {paymentTypeOwned()}
        <button>Click to Select</button>
      </div>
    </div>
  );
};

PropertyTransactionsDisplay.propTypes = {
  propertyInfo: propTypes.instanceOf(Object).isRequired,
  handlePropertySelect: propTypes.func.isRequired,
};

export default PropertyTransactionsDisplay;
