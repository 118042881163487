/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import translate from "translate";
import { useSearchParams } from 'react-router-dom';
import '../../assets/css/miscPages.css';

// translate.engine = "google";

const AboutUs = ({currentLang}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState('home');
  const [aboutText, setAboutText] = useState(t('aboutText'));
  const [termsText, setTermsText] = useState(t('termsText'));
  const [privacyText, setPrivacyText] = useState(t('privacyText'));
  const [searchParams, setSearchParams] = useSearchParams();

  const aboutRef = useRef(null);
  const termsRef = useRef(null);
  const privacyRef = useRef(null);

  // Set scroll focus to review section when review search param present and true
  useEffect(() => {
    if (searchParams.get('legal_terms') === 'terms_and_conditions') {
      setPage('terms');
    }

    if (searchParams.get('legal_terms') === 'privacy_statement') {
      setPage('privacy');
    }
  }, [searchParams])

  useEffect(() => {
    const aboutDiv = aboutRef.current;
    setAboutText(t('aboutText'));
    if (aboutDiv) aboutDiv.innerHTML = aboutText;
  }, [page, aboutRef, aboutText, currentLang, t]);

  useEffect(() => {
    const termsDiv = termsRef.current;
    setTermsText(t('termsText'));
    if (termsDiv) termsDiv.innerHTML = termsText;
  }, [page, termsRef, termsText, currentLang, t]);

  useEffect(() => {
    const privacyDiv = privacyRef.current;
    setPrivacyText(t('privacyText'));
    if (privacyDiv) privacyDiv.innerHTML = privacyText;
  }, [page, privacyRef, privacyText, currentLang, t]);

  return (
    <div className='screen-height'>
      <div className='aboutUsBG'>
      <span role="img" aria-label="https://unsplash.com/photos/fR4nZ8xHMwA?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"/>  
      <div className='container-md'>
        <div className='aboutUsForeground mx-auto'>
        <h1 className='mt-3'>{t('about')}</h1>
        <div className='flex-row border-bottom mb-2'>
          <h4><a className='mr-1' href='about?legal_terms=home'>• {t('about')}</a></h4>
          <h4><a className='mr-1' href='about?legal_terms=terms_and_conditions'>• {t('terms')}</a></h4>
          <h4><a href='about?legal_terms=privacy_statement'>• {t('privacy')}</a></h4>
        </div>
        {page === 'home' && (
          <p ref={aboutRef} className='size-1'>
            {aboutText}
          </p>
        )}
        {page === 'terms' && (
          <div ref={termsRef} className=''>
            {termsText}
          </div>
        )}
        {page === 'privacy' && (
          <div ref={privacyRef} className=''>
            {privacyText}
          </div>
        )}

      </div>
      </div>
    </div>
    </div>
  )
};

export default AboutUs;
