/* eslint-disable no-unused-vars */

import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from '../../misc/misc';
import Swal from 'sweetalert2';
// import Star from '../../../assets/images/Star.svg';
import Star from '../../../assets/images/star2_full.svg';
import Cog from '../../../assets/images/cog.svg';
import Verified from '../../../assets/images/logo-simple.png';

const PropertyDisplayThumb = ({ user, property, handlePropertySelect,
                                handlePropertyRemove, handlePropertyRequests,
                                setRedirect,
                                showPropertyType = true, showUnitNumber = false,
                                isPropertyGroup = false }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const { id, property_type, street_address, po_box,
          fee, owner_id, resident_id, property_group_id,
          sq_ft, studio, bedrooms, bathrooms, property_group,
          island, city, settlement, property_unit, images, cover_photo_index,
          rating, user_id, verified,
          // Property Groups
          group_name, summary, first_unit_id,
        } = property;
  // const dateEnd = created_at.indexOf('T');
  // const shortDate = created_at.substring(0, dateEnd);
  const bodyElem = useRef(null);
  let thumbHeader = null
  if(showPropertyType){
    thumbHeader = property_type;
  } else {
    thumbHeader = property_group_id ? `${property_group?.city}, ${property_group?.island}` : `${city}, ${island}`
  }
  let shortDesc = `${bedrooms} ${t('t_beds')} | <b>$${fee}</b><br/> ${bathrooms} ${t('t_baths')} | ${numberWithCommas(sq_ft)} Sq Ft`;
  let priceRange = null;
  if(studio){shortDesc = `${t('g_studio')} | <b>$${fee}</b><br/> ${bathrooms} ${t('t_baths')} | ${numberWithCommas(sq_ft)} Sq Ft`};
  if(isPropertyGroup){
    shortDesc = summary?.desc;
    priceRange = `$${numberWithCommas(summary?.all?.price_range?.min)} - $${numberWithCommas(summary?.all?.price_range?.max)}`;
  }
  if(isPropertyGroup){
    shortDesc = shortDesc?.replaceAll('1', 'one')?.replaceAll('2', 'two')
    .replaceAll('3', 'three').replaceAll(' ', '_')
    .replaceAll('-', '').replace('__', '_');
  }
  // Fill body element of post with html rich text
  useEffect(() => {
    const bodyDiv = bodyElem.current;
    if (bodyDiv) bodyDiv.innerHTML = shortDesc;
  }, [shortDesc]);

  const propertySettings = async (isGroup = false, myHome = false) => {
    if (isGroup) { return setRedirect(`/properties/edit/${first_unit_id}?group_name=${group_name}&group_id=${id}`); }

    if (!myHome) {
      const result = await Swal.fire({
        // title: 'What would you like to do?',
        icon: 'question',
        // toast: true,
        showConfirmButton: true,
        confirmButtonText: t('g_tenantForums'),
        confirmButtonColor: '#6396c9',
        showDenyButton: true,
        denyButtonText: t('g_editProperty'),
        denyButtonColor: '#82adba',
        showCancelButton: true,
        cancelButtonText: t('g_removeProperty'),
        cancelButtonColor: '#a64a3d',
        // customClass: {
        //   actions: 'flex-col',
        // }
      })
      
      if (result.isConfirmed) { setRedirect(`/properties/forms/${id}`) }
      if (result.isDenied) { setRedirect(`/properties/edit/${id}`); }
      if (result.dismiss === Swal.DismissReason.cancel) { handlePropertyRemove(id); }
    } else {
      const result = await Swal.fire({
        // title: 'What would you like to do?',
        icon: 'question',
        // toast: true,
        showConfirmButton: true,
        confirmButtonText: t('g_tenantForums'),
        confirmButtonColor: '#6396c9',
        showDenyButton: false,
        showCancelButton: false,
      })
      
      if (result.isConfirmed) { setRedirect(`/properties/forms/${id}`) }
    }
  };

  const populateTag = () => {
    if(isPropertyGroup){
      if (user?.id === user_id) {return (<div className='property-owner-tag'>{t('t_propertyOwned')}</div>)}
      else { return null }
    }
    if (resident_id !== null) {
      if (user?.id === resident_id) {
        return (<div className='property-renter-tag'>{t('t_yourHome')}</div>)
      } else {
        return (<div className='property-renter-tag'>{t('t_rented')}</div>)
      }
    } else {
      if (user?.id === owner_id) return (<div className='property-owner-tag'>{t('t_propertyOwned')}</div>)
    }
  };

  const renderProperty = () => (
    <li>
      <div className='flex-row' style={{position: 'absolute', zIndex: '2', right: 0}}>
      {user?.id === owner_id && (
        <button type='button' className='property-thumb-btn' onClick={e => propertySettings()}>
          <img src={Cog} alt='settings' style={{width:'40px'}} className='cog-spin'/>
        </button>
      )}
      {/* {(user?.id === resident_id && resident_id !== null) && <button type='button' className='property-thumb-btn' onClick={e => handlePropertyRequests(id)}>{t('t_repairsReq')}</button>} */}
      {(user?.id === resident_id && resident_id !== null) && (
          <button type='button' className='property-thumb-btn' onClick={e => propertySettings(false, true)}>
            <img src={Cog} alt='settings' style={{width:'40px'}} className='cog-spin'/>
          </button>
        )}
      </div>
      <button key={id} onClick={() => handlePropertySelect(property)} className='property-thumb rental-box'>
        <div className='half-vert-img'>
          <img src={property_group_id ? property_group?.images[property_group?.cover_photo_index || 0]?.url : images[cover_photo_index || 0]?.url} alt='property listing'></img>
          {/* {verified && (
            <div className='flex-row verified-btn-thumb' style={{position: 'absolute', bottom: '10px', left: '5%'}} title='Verified Listing'>
              <img alt='verified listing' src={Verified} style={{width: '38px'}}/>
            </div>
          )} */}
          <div className='rating-overlay'><img className='rating-star filter-star' src={Star} alt='star rating'/>({rating.score}/5)</div>
        </div>
        <div className='half-vert p-1'>
          {(property_group_id && showPropertyType) && <h2 className='text-camel text-grey'><b>{property_group?.group_name || settlement}</b></h2>}
          {(!property_group_id && showPropertyType) && <h2 className='text-camel text-grey'><b>{settlement}</b></h2>}
          {/* <h2 className='text-camel'><b>{thumbHeader}</b></h2> */}
          {(!showUnitNumber && !showPropertyType) && (<h2 className='text-camel'>
            {property_group_id ? property_group?.settlement : settlement}
          </h2>)}
          {(showUnitNumber && property_group) && (<h2 className='text-camel'>
              {property_unit}
            </h2>)}
          <div>
            {(showPropertyType && !showUnitNumber && property_group) && (<span className='text-camel'>
              {property_group_id ? `${property_group?.settlement}, ` : settlement}
            </span>)}
            <span className='text-camel'>
              {(showUnitNumber && property_group) && `${property_group?.settlement}, `}
              {property_group_id ? property_group?.street_address : street_address}, {''}
            </span>
            <span className='text-caps'>
              {property_group_id ? property_group?.po_box : po_box}
            </span>
          </div>
          <div ref={bodyElem}>{shortDesc}</div>
        </div>
      </button>
      {populateTag()}
    </li>
  );

  const renderPropertyGroup = () => (
    <li>
      {/* <div className='flex-row' style={{position: 'absolute', zIndex: '2'}}>
      {user?.id === user_id && <button type='button' className='property-thumb-btn danger-btn' onClick={e => handlePropertyRemove(id)}>{t('g_removeProperty')}</button>}
      </div> */}
      <div className='flex-row' style={{position: 'absolute', zIndex: '2', right: 0}}>
        {user?.id === user_id && (
          <button type='button' className='property-thumb-btn' onClick={e => propertySettings(true)}>
            <img src={Cog} alt='settings' style={{width:'40px'}} className='cog-spin'/>
          </button>
        )}
      </div>
      <button key={id} onClick={() => handlePropertySelect(property, true)} className='property-thumb rental-box'>
        <div className='half-vert-img'>
          <img src={images[cover_photo_index || 0]?.url} alt='property listing'></img>
          {/* {verified && (
            <div className='flex-row verified-btn-thumb' style={{position: 'absolute', bottom: '10px', left: '5%'}} title='Verified Listing'>
              <img alt='verified listing' src={Verified} style={{width: '38px'}}/>
            </div>
          )} */}
          <div className='rating-overlay'><img className='rating-star filter-star' src={Star} alt='star rating'/>({rating || 0}/5)</div>
        </div>
        <div className='half-vert p-1'>
          <h2 className='text-camel'><b>{group_name}</b></h2>
          {/* <div className='text-camel'><b>{thumbHeader}</b></div> */}
          <div className='text-camel'>{settlement}, {street_address}, <span className='text-caps'> {po_box}</span></div>
          <div className='text-camel'>{`${t(shortDesc)} | ${priceRange}`}</div>
        </div>
      </button>
      {populateTag()}
    </li>
  );

  return isPropertyGroup ? renderPropertyGroup() : renderProperty()
};

PropertyDisplayThumb.propTypes = {
  user: propTypes.instanceOf(Object),
  property: propTypes.instanceOf(Object).isRequired,
  handlePropertySelect: propTypes.func.isRequired,
  handlePropertyRemove: propTypes.func,
  handlePropertyRequests: propTypes.func,
  setRedirect: propTypes.func,
  isPropertyGroup: propTypes.bool.isRequired
};

export default PropertyDisplayThumb;
