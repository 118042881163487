import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import propTypes from 'prop-types';
import { GoogleMapsApiKey } from '../../misc/apiRequests';
import '../../../assets/css/googlemaps.css';

// const containerStyle = {
//   width: '300px',
//   height: '300px'
// };


const MapsComponent = ({ property }) => {
  const { latitude, longitude } = property;
  const center = {
    lat: parseFloat(latitude) || 0,
    lng: parseFloat(longitude) || 0
  };

  const { isLoaded, loadError } = useJsApiLoader ({
    googleMapsApiKey: GoogleMapsApiKey // ,
    // ...otherOptions
  })

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    // const onLoad = React.useCallback(
    //   function onLoad (mapInstance) {
    //     // do something with map Instance
    //   }
    // )
    return <GoogleMap
      mapContainerClassName={'googlemaps'}
      center={center}
      zoom={16}
      // onLoad={onLoad}
    >
      {
        // ...Your map components
        <Marker position={center} />
      }
    </GoogleMap>
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : null
}

MapsComponent.propTypes = {
  property: propTypes.instanceOf(Object).isRequired,
};

export default React.memo(MapsComponent);
