import React from 'react';
import propTypes from 'prop-types';
import '../../../assets/css/property.css';

const CoverPhoto = ({ allImages, coverPhotoIndex, handleCoverPhotoSelect, imageType }) => {
  // const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSelect = (e, i) => {
    // setSelectedIndex(i);
    const oldElem = document.querySelector(`#${imageType} > .selected-cover-btn`);
    oldElem.classList.toggle('selected-cover-btn');
    e.currentTarget.classList.toggle('selected-cover-btn');
    
    handleCoverPhotoSelect(i);
  };

  const populateImages = () => allImages.map((img, index) => (
    <button key={img.id} type="button" className={`img-upload-btn mr-05 mb-05 ${coverPhotoIndex === index && 'selected-cover-btn'}`} onClick={(e) => handleSelect(e, index)}>
      <img alt="Property" src={img.url} />
    </button>
  ))


  return (
    <div id={imageType} className='text-center flex-row mt-1'>
      {populateImages()}
    </div>
  );
};

CoverPhoto.propTypes = {
  allImages: propTypes.instanceOf(Array).isRequired,
  coverPhotoIndex: propTypes.number.isRequired,
  handleCoverPhotoSelect: propTypes.func,
  imageType: propTypes.string,
};

export default CoverPhoto;
