/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchProperty, fetchComment, newComment, updateComment } from '../../misc/apiRequests';
// import { propertiesData } from '../../misc/jsonData/propertyListingData';
// import { commentsJSON, selectedComments1JSON, selectedComments2JSON } from '../../misc/jsonData/forumData';
import PageIndex from '../pageIndex';
import PropertyForumReplyDisplay from '../../presentational/properties/propertyForumReplyDisplay';
import ForumNewComment from '../../presentational/properties/forumNewComment';
import '../../../assets/css/propertyForms.css';
import Swal from 'sweetalert2';

const PropertyForumDisplay = ({ user, setRedirect, handleLoader, handleModal, handleMessagesModal, handleImageModal }) => {
  const { t } = useTranslation();

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [mainComment, setMainComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [pageNumb, setPageNumb] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [numbRecords, setNumbRecords] = useState(0);
  const [newCommentMode, setNewCommentMode] = useState(false);
  const [editCommentMode, setEditCommentMode] = useState(false);
  const [commentTitle, setCommentTitle] = useState('');
  const [commentBody, setCommentBody] = useState('');
  const [commentImages, setCommentImages] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);
  const [commentType, setCommentType] = useState('repair');

  const uploadsInputRef = useRef(null);
  const location = useLocation()


  let { id, comment_id } = useParams();

  // const handleIndexChange = (e, nextPage = false) => {
  //   e.preventDefault();
  //   if(!nextPage && ((pageNumb - 1) !== 0)) setPageNumb(pageNumb - 1);
  //   if(nextPage && ((pageNumb + 1) <= maxPages)) setPageNumb(pageNumb + 1);
  // };

  const handleNewCommentMode = (mode = true) => {
    setNewCommentMode(!newCommentMode);
    setCommentImages([]);
    setRemoveImages([]);
  };

  const handleEditCommentMode = (mode = true) => {
    setEditCommentMode(!editCommentMode);
    setCommentImages([]);
    setRemoveImages([]);
  };

  const populateImages = (comment) => comment.images.map((img) => (
    <button type="button" key={img.id} onClick={() => handleImageModal(img.url)}><img className="img-thumb" alt="Repair" src={img.url} /></button>
  ));

  // Populate all subforums and related posts paginated by 5 posts per page
  const populateReplies = () => comments.map(commentData => (
    <PropertyForumReplyDisplay
      key={commentData.id}
      user={user}
      mainComment={mainComment}
      comment={commentData}
      populateImages={populateImages}
      commentBody={commentBody}
      setCommentBody={setCommentBody}
      setCommentImages={setCommentImages}
      setRemoveImages={setRemoveImages}
      handleCheckFileSize={handleCheckFileSize}
      handleSubmitEdit={handleSubmitEdit}
      handleSubmitNew={handleSubmitNew}
      handleNewCommentMode={handleNewCommentMode}
      handleEditCommentMode={handleEditCommentMode}
      handleImageModal={handleImageModal}
      handleImageRemoval={handleImageRemoval}
    />
  ));

  // const dateEnd = created_at.indexOf('T');
  // const shortDate = created_at.substring(0, dateEnd);

  // Fetch Property by ID
  useEffect(() => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      fetchProperty(propertyID)
        .then(response => {
          if (response.success) {
            setSelectedProperty(response.record);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [id, handleLoader, handleModal]);

  // Fetch Related Comments for Selected Comment
  useEffect(() => {
    if (mainComment?.id !== null) {
        handleLoader(true);
        const commentID = parseInt(comment_id, 10);
        fetchComment(perPage, pageNumb, commentID)
          .then(response => {
            if (response.success) {
              setMainComment(response.main_record);
              setComments(response.records);
              setNumbRecords(response.records_params.no_of_records);
              setMaxPages(response.records_params.max_page || 1);
            }
            if (!response.success) {
              // handleModal(response.errors);
              Swal.fire({
                position: 'top-end',
                toast: true,
                timerProgressBar: true,
                icon: 'error',
                title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
                showConfirmButton: false,
                timer: 2000
              })
            }
            handleLoader(false);
          });
    }
  }, [id, handleLoader, handleModal, perPage, pageNumb, comment_id, mainComment?.id]);

    // Ensures that the comment images uploaded doesn't go too far over 1 megabyte
    const handleCheckFileSize = e => {
      const elem = e.target;
      let overlimit = false;
      const errorList = [];
      Array.from(elem.files).forEach((x, index) => {
        if (elem.files[index].size > 1048576) {
          console.log(elem.files[index]);
          errorList.push(`${x.name} - File size too big (max size allowed: 1 megabyte)`);
          overlimit = true;
        } else { 
          const imagesList = commentImages;
          imagesList.push(elem.files[index]);
          setCommentImages(imagesList);
        }
      })
  
      if (overlimit) {
        elem.value = '';
        setCommentImages([]);
        handleMessagesModal(errorList);
      }
    };
  
  const handleSubmitNew = e => {
    e.preventDefault();

    const commentData = new FormData();
    commentData.append('comment[comment_type]', commentType);
    commentData.append('comment[title]', mainComment.title);
    commentData.append('comment[comment_id]', mainComment?.id);

    commentData.append('comment[body]', commentBody);
    commentImages.forEach((x) => commentData.append("comment[images][]", x))

    handleLoader(true);
    newComment(commentData, id)
      .then(response => {
        if (response.success) {
          handleMessagesModal(response.message);
          setMainComment(response.main_record);
          setComments(response.records);
          setNumbRecords(response.records_params.no_of_records);
          setMaxPages(response.records_params.max_page || 1);
          setNewCommentMode(false);
          setRemoveImages([]);
          setCommentImages([]);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const handleSubmitEdit = (e, comment) => {
    e.preventDefault();

    const commentData = new FormData();
    commentData.append('comment[comment_type]', commentType);
    commentData.append('comment[title]', comment.title);
    commentData.append('comment[body]', commentBody);
    // updateComment.append('comment[comment_id]', mainComment.id);
    removeImages.forEach((x) => commentData.append("comment[remove_images][]", x))
    commentImages.forEach((x) => commentData.append("comment[images][]", x))

    handleLoader(true);
    updateComment(comment.id, commentData)
      .then(response => {
        if (response.success) {
          handleMessagesModal(response.message);
          setMainComment(response.main_record);
          setComments(response.records);
          setNumbRecords(response.records_params.no_of_records);
          setMaxPages(response.records_params.max_page || 1);
          setEditCommentMode(false);
          setRemoveImages([]);
          setCommentImages([]);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const handleImageRemoval = (e, id) => {
    e.preventDefault();

    const idFound = removeImages.find(x => x === id);
    let newList = [];

    if (idFound) {
      newList = removeImages.filter(x => x !== id);
    } else {
      newList = removeImages;
      newList.push(id);
    }
    setRemoveImages(newList);
  };

  // Fetch FAKE DATA Property by ID
  // useEffect(() => {
  //   if (id) {
  //     const propertyID = parseInt(id, 10);
  //     const property = propertiesData.filter(x => x.id === propertyID)
  //     if (property[0]) {
  //       setSelectedProperty(property[0]);
  //     }
  //     else {
  //       handleModal(['Property with given ID not found']);
  //     }
  //   }
  // }, [id, handleModal]);

  // Fetch FAKE DATA Comments by Property ID
  // useEffect(() => {
  //   const commentID = parseInt(comment_id, 10);
  //   if(commentID === 1){
  //     setMainComment(selectedComments1JSON.main_record);
  //     setComments(selectedComments1JSON.records.slice(pageNumb - 1, (maxPages / perPage) - (perPage - pageNumb)));
  //     // console.log(`${maxPages} / ${perPage} - (${perPage} - ${pageNumb}) = ${(maxPages / perPage) - (perPage - pageNumb)}`)
  //     setMaxPages(Math.ceil(selectedComments1JSON.record_params.no_of_records / perPage));
  //     setNumbRecords(selectedComments1JSON.record_params.no_of_records);
  //   }
  //   if(commentID === 2){
  //     setMainComment(selectedComments2JSON.main_record);
  //     setComments(selectedComments2JSON.records.slice(pageNumb - 1, (maxPages / perPage) - (perPage - pageNumb)));
  //     // console.log(`${maxPages} / ${perPage} - (${perPage} - ${pageNumb}) = ${(maxPages / perPage) - (perPage - pageNumb)}`)
  //     setMaxPages(Math.ceil(selectedComments2JSON.record_params.no_of_records / perPage));
  //     setNumbRecords(selectedComments2JSON.record_params.no_of_records);
  //   }
  // }, [perPage, pageNumb, maxPages, comment_id]);

  // Unauthorized redirect
  useEffect(() => {
    // if(!user.logged_in) { setRedirect("/"); }
  }, [user]);

  return (
    <div id='PropertyForms' className=''>
      {(newCommentMode || editCommentMode) && (<div className="modal">
        <div className="modal-content" style={{minHeight: 'auto', maxHeight: 'none'}}>
          <div className='container'>
            {/* Call the new comment component */}
            {
              newCommentMode && (
                <ForumNewComment
                  mainComment={mainComment}
                  comment={mainComment}
                  commentBody={commentBody}
                  setCommentBody={setCommentBody}
                  setCommentImages={setCommentImages}
                  handleSubmitNew={handleSubmitNew}
                  handleSubmitEdit={handleSubmitEdit}
                  handleCheckFileSize={handleCheckFileSize}
                  handleNewCommentMode={handleNewCommentMode}
                  handleEditCommentMode={handleEditCommentMode}
                  handleImageModal={handleImageModal}
                  handleImageRemoval={handleImageRemoval}
                />
              )
            }
            {/* Call the new comment component with edit comment flag */}
            {
              editCommentMode && (
                <ForumNewComment
                  editComment={true}
                  mainComment={mainComment}
                  comment={mainComment}
                  commentBody={commentBody}
                  setCommentBody={setCommentBody}
                  setCommentTitle={setCommentTitle}
                  setCommentImages={setCommentImages}
                  handleSubmitNew={handleSubmitNew}
                  handleSubmitEdit={handleSubmitEdit}
                  handleCheckFileSize={handleCheckFileSize}
                  handleNewCommentMode={handleNewCommentMode}
                  handleEditCommentMode={handleEditCommentMode}
                  handleImageModal={handleImageModal}
                  handleImageRemoval={handleImageRemoval}
                />
              )
            }
          </div>
        </div>
      </div>
      )}
      <div className='container'>
        <h2 className='text-center mt-2 mb-2'>{t('property_forum')}</h2>
        <div className='property-navbar mt-2'>
          <span className="text-grey text-camel">{selectedProperty?.property_group ? t('apartmentComplex') : selectedProperty?.property_type}</span>
          {selectedProperty?.property_group && <h2 className=''>{selectedProperty?.property_group?.group_name}</h2>}
          <a className="pb-1" style={{display: 'block'}} href={`${location.pathname.substring(0, location.pathname.search('comment'))}`}>
            <h3 className="text-camel">{selectedProperty?.settlement || t('f_settlement')}, {selectedProperty?.island}</h3>
            <div className="text-camel">{selectedProperty?.street_address}, <span className="text-caps">{selectedProperty?.po_box}</span></div>
            <button className='btn mt-1'>{'<< Back'}</button>
          </a>
          {selectedProperty?.property_group && <span className="text-grey text-camel">{selectedProperty?.property_unit || `${t('f_unit')} #`}</span>}
        </div>
        <div className='mt-3 forum-thread'>
        <div className='flex-row'>
          <h2>{mainComment?.title}</h2>
          <img alt='User Avatar' src={mainComment?.user?.photo} />
          {(!editCommentMode && mainComment?.user_id === user?.id) && <button type='button' onClick={() => handleEditCommentMode()} className="reply-btn ml-1">{t('f_edit')}...</button>}
        </div>
        <div className='flex-col'>
          <span className='text-camel size-4 text-bold'>{mainComment?.user.profile.first_name} {mainComment?.user.profile.last_name}</span>
          <span className='size-01'>{t('f_posted')}: {mainComment?.created_at} {mainComment?.created_at !== mainComment?.updated_at && `, ${t('f_edited')}: ${mainComment?.updated_at}`}</span>
        </div>
        <div>
          {mainComment?.images?.length > 0 && <div className='text-bold mt-1'>{t('f_attachedImages')}...</div>}
          <div className='flex-row'>
            {mainComment && populateImages(mainComment)}
          </div>
        </div>
        {!editCommentMode && <p className='size-4'>{mainComment?.body}</p>}

        {!newCommentMode && <button type='button' className='reply-btn' onClick={() => handleNewCommentMode()}>{t('f_reply')}...</button>}
        </div>
        <div className='mt-2'>
          <h2>{t('f_replies')}</h2>
          {populateReplies()}
        </div>
        <div>
          {maxPages > 1 && <PageIndex setPage={setPageNumb} setPerPage={setPerPage} page={pageNumb} perPage={perPage} maxPages={maxPages} viewMore={false} />}
          {/* <div>
            <span>showing {perPage} record(s) per page</span><br/>
            <span>total records: {numbRecords}</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

PropertyForumDisplay.propTypes = {
  setRedirect: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired,
};

export default PropertyForumDisplay;
