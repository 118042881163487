/* eslint-disable no-unused-vars */

import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImageGallery from '../../functional/imageGallery';
import Star from '../../../assets/images/Star.svg';

const ForumNewComment = ({
  editComment = false, mainComment, comment, commentTitle, setCommentTitle,
  commentBody, setCommentBody, setCommentImages, handleSubmitNew, handleSubmitEdit, handleCheckFileSize,
  handleNewCommentMode, handleEditCommentMode, handleImageRemoval, handleImageModal
}) => {
  const { t } = useTranslation();

  const uploadsInputRef = useRef(null);

  const submitFunction = e => {
    if(editComment) { return handleSubmitEdit(e, comment); }
    if(!editComment) { return handleSubmitNew(e); }
  }

  const handleClearImages = () => {
    const uploadsInputElem = uploadsInputRef.current;

    uploadsInputElem.value = '';
    setCommentImages([]);
  }

  const populateImages = () => comment.images.map(img => (
    <ImageGallery key={img.id} imgInfo={img} handleImageModal={handleImageModal} handleImageRemoval={handleImageRemoval} />
  ));

  useEffect(() => {
    if(editComment) { 
      setCommentBody(comment.body)
    } else { setCommentBody(''); }
  }, [comment, editComment, setCommentBody]);

  return (
    <div>
      <form className="property-forms" onSubmit={submitFunction} encType="multipart/form-data">
        {(mainComment === null && comment === null) && (
          <div>
            <span className='form-sub-text'>{t('title')}</span>
            <input
              type="text"
              value={commentTitle}
              onChange={e => setCommentTitle(e.target.value)}
              minLength="3"
              required
            />
          </div>
        )}

        <h3 className='form-sub-text'>{t('enterText')}</h3>
        <textarea
          rows="4"
          cols="40"
          value={commentBody}
          onChange={e => setCommentBody(e.target.value)}
        />

        <h3 className='form-sub-text'>{t('f_photo')}</h3>
        {editComment && <span className='form-sub-text text-grey'>* {t('f_propertyPhotoDesc2')}</span>}
        {editComment && <div className='flex-row mb-1'>{populateImages()}</div>}
        <input
          type="file"
          multiple
          ref={uploadsInputRef}
          alt='Comment photo uploads'
          name="photo_uploads"
          accept="image/png, image/jpeg, image/jpg"
          // value={photoUpload}
          onChange={e => handleCheckFileSize(e)}
        />
        <button type='button' className='danger-btn' onClick={() => handleClearImages()} >{t('f_clearImage')}</button>

        <div className='mt-1'>
          {editComment && <button type="button" className='danger-btn' onClick={() => handleEditCommentMode()}>{t('f_cancel')}</button>}
          {!editComment && <button type="button" className='danger-btn' onClick={() => handleNewCommentMode()}>{t('f_cancel')}</button>}
          {editComment && <button type="submit" className='ml-1'>{t('f_submit')}</button>}
          {!editComment && <button type="submit" className='ml-1'>{t('f_reply')}</button>}
        </div>

      </form>
    </div>
  );
};

ForumNewComment.propTypes = {
  mainComment: propTypes.instanceOf(Object),
  comment: propTypes.instanceOf(Object),
  handleSubmitNew: propTypes.func.isRequired,
  handleSubmitEdit: propTypes.func.isRequired,
  handleCheckFileSize: propTypes.func.isRequired,
  handleImageRemoval: propTypes.func.isRequired,
  commentTitle: propTypes.string,
  setCommentTitle: propTypes.func,
  commentBody: propTypes.string,
  setCommentBody: propTypes.func.isRequired,
  setCommentImages: propTypes.func.isRequired
};

export default ForumNewComment;
