/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fetchFavorites } from '../../misc/apiRequests';
import PropertyDisplayThumb from '../../presentational/properties/propertyDisplayThumb';
import PageIndex from '../../presentational/pageIndex';
import Swal from 'sweetalert2';
// import { propertiesData } from '../../misc/jsonData/PropertyFavoritesData';

const PropertyFavorites = ({
  user,
  handlePropertyRequests, handlePropertyRemove, handlePropertySelect, handleLoader, handleModal
}) => {
  const { t } = useTranslation();

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [allProperties, setAllProperties] = useState([]);

  // const handleIndexChange = (e, nextPage = false) => {
  //   e.preventDefault();
  //   if(!nextPage && ((page - 1) !== 0)) setPage(page - 1);
  //   if(nextPage && ((page + 1) <= maxPages)) setPage(page + 1);
  // };

  // Populate all properties
  const populateAllProperties = () => allProperties.map(propertyData => {
    if(propertyData.hasOwnProperty("group_name")) {
      return <PropertyDisplayThumb
        key={propertyData.id}
        property={propertyData}
        user={user}
        handlePropertyRequests={handlePropertyRequests}
        handlePropertyRemove={handlePropertyRemove}
        handlePropertySelect={handlePropertySelect}
        showPropertyType={false}
        isPropertyGroup={true}
      />
    } else {
      return <PropertyDisplayThumb
        key={propertyData.id}
        property={propertyData}
        user={user}
        handlePropertyRequests={handlePropertyRequests}
        handlePropertyRemove={handlePropertyRemove}
        handlePropertySelect={handlePropertySelect}
        showPropertyType={false}
        isPropertyGroup={false}
      />
    }
  });


  // Fetch related Property Group
  useEffect(() => {
    handleLoader(true);
    fetchFavorites(perPage, page)
      .then(response => {
        if (response.success) {
          setAllProperties(response.records);
          setMaxPages(response.records_params.max_page || 1);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  }, [handleLoader, handleModal, page, perPage])

  return (
    <div id='PropertyPage'>
      <div id='PropertyThumb' className='section-b'>
        <div className='container'>
          <h2 className='text-center mt-3'>{t('favoritedProperty')}</h2>
          <div className='mt-2 property-thumb'>
            <ul>
              {populateAllProperties()}
            </ul>
            <div className='text-center p-1'>
              {maxPages > 1 && <PageIndex setPage={setPage} setPerPage={setPerPage} page={page} perPage={perPage} maxPages={maxPages} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PropertyFavorites.propTypes = {
  user: propTypes.instanceOf(Object),
  handlePropertyRequests: propTypes.func.isRequired,
  handlePropertyRemove: propTypes.func.isRequired,
  handlePropertySelect: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
};

export default PropertyFavorites;