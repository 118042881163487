/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import propTypes from 'prop-types';
import '../../../../assets/css/property.css';
// import Star from '../../../../assets/images/Star.svg';
import TenantModal from '../../tenantModal';

const TenantListDisplay = ({ tenantInfo, tenantModal, setTenantModal, handleAddTenant, handleDenyTenant, handleRemoveTenant, isTenant = false }) => {
  // eslint-disable-next-line camelcase
  const { id, email, profile, photo, tenant_history, tenant_avg_score, appointment } = tenantInfo;
  // const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setTenantModal(!tenantModal);
    if (tenantModal) window.location.reload(true);
  }

  return (
    <div className='mr-1'>
      <div className="flex-col tenant-box">
        <div className='text-center'>
          <button onClick={() => handleModal()} type='button'>
            <img className='tenant-pic' alt="Profile pic" src={photo} />
          </button>
          <div className='text-camel text-bold'>{profile?.first_name} {profile?.middle_name} {profile?.last_name}</div>
          {appointment && <div className='text-camel'>Dates Selected: <br/>
            <b>{appointment?.rental_date}</b> to <b>{appointment?.rental_date_until}</b>
          </div>}
        </div>
      </div>
      { tenantModal && <TenantModal
                        info={tenantInfo}
                        handleModal={handleModal}
                        handleDenyTenant={handleDenyTenant}
                        handleAddTenant={handleAddTenant}
                        handleRemoveTenant={handleRemoveTenant}
                        isTenant={isTenant}
                      />}
    </div>
  );
};

TenantListDisplay.propTypes = {
  tenantInfo: propTypes.instanceOf(Object).isRequired,
  tenantModal: propTypes.bool.isRequired,
  setTenantModal: propTypes.func.isRequired,
  handleAddTenant: propTypes.func.isRequired,
  handleDenyTenant: propTypes.func.isRequired,
  handleRemoveTenant: propTypes.func.isRequired,
};

export default TenantListDisplay;
