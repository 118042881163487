/* eslint-disable no-unused-vars */

import React, {useRef, useState} from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ForumNewComment from '../../presentational/properties/forumNewComment';
import '../../../assets/css/propertyForms.css';

const PropertyForumReplyDisplay = ({
  user,
  mainComment, comment, populateImages,
  commentBody, setCommentBody,
  setCommentImages, setRemoveImages,
  handleSubmitEdit, handleSubmitNew, handleCheckFileSize,
  handleImageModal, handleImageRemoval
}) => {
  const { t } = useTranslation();

  const [newCommentMode, setNewCommentMode] = useState(false);
  const [editCommentMode, setEditCommentMode] = useState(false);
  
  const handleNewCommentMode = (mode = true) => {
    setNewCommentMode(!newCommentMode);
    setCommentImages([]);
    setRemoveImages([]);
  };

  const handleEditCommentMode = (mode = true) => {
    setEditCommentMode(!editCommentMode);
    setCommentImages([]);
    setRemoveImages([]);
  };

  return (
    <div>
      {(newCommentMode || editCommentMode) && (<div className="modal">
        <div className="modal-content" style={{minHeight: 'auto', maxHeight: 'none'}}>
          <div className='container'>
            {/* Call the new comment component */}
            {
              newCommentMode && (
                <ForumNewComment
                  editComment={false}
                  mainComment={mainComment}
                  commentBody={commentBody}
                  setCommentBody={setCommentBody}
                  setCommentImages={setCommentImages}
                  handleSubmitNew={handleSubmitNew}
                  handleSubmitEdit={handleSubmitEdit}
                  handleCheckFileSize={handleCheckFileSize}
                  handleNewCommentMode={handleNewCommentMode}
                  handleEditCommentMode={handleEditCommentMode}
                  handleImageModal={handleImageModal}
                  handleImageRemoval={handleImageRemoval}
                />
              )
            }
            {/* Call the new comment component with edit comment flag */}
            {
              editCommentMode && (
                <ForumNewComment
                  editComment={true}
                  mainComment={mainComment}
                  comment={comment}
                  commentBody={commentBody}
                  setCommentBody={setCommentBody}
                  setCommentImages={setCommentImages}
                  handleSubmitNew={handleSubmitNew}
                  handleSubmitEdit={handleSubmitEdit}
                  handleCheckFileSize={handleCheckFileSize}
                  handleNewCommentMode={handleNewCommentMode}
                  handleEditCommentMode={handleEditCommentMode}
                  handleImageModal={handleImageModal}
                  handleImageRemoval={handleImageRemoval}
                />
              )
            }
          </div>
        </div>
      </div>
      )}
      <div className='container'>
        <div className='forum-thread'>
        <div>
          <div className='flex-row'>
            <h2>{comment?.user.profile.first_name} {comment?.user.profile.last_name}</h2>
            <img alt='User Avatar' src={comment?.user?.photo} />
            {(!editCommentMode && comment?.user_id === user?.id) && <button type='button' onClick={() => handleEditCommentMode()} className="reply-btn ml-1">{t('f_edit')}...</button>}
          </div>
          <span className='size-01'>{t('f_posted')}: {comment?.created_at} <span className='text-grey'>{comment?.created_at !== comment?.updated_at && `, ${t('f_edited')}: ${comment?.updated_at}`}</span></span>
        </div>
        <div>
          {comment?.images?.length > 0 && <div className='text-bold mt-1'>{t('f_attachedImages')}...</div>}
          <div className='flex-row'>
            {comment && populateImages(comment)}
          </div>
        </div>
        {!editCommentMode && <p className='size-4'>{comment?.body}</p>}

        {newCommentMode === false && <button type='button' className='reply-btn' onClick={() => handleNewCommentMode()}>{t('f_reply')}...</button>}
      </div>
      </div>
    </div>
  );
};

PropertyForumReplyDisplay.propTypes = {
  user: propTypes.instanceOf(Object).isRequired,
  mainComment: propTypes.instanceOf(Object),
  comment: propTypes.instanceOf(Object).isRequired,
  populateImages: propTypes.func.isRequired,
  handleSubmitNew: propTypes.func.isRequired,
  handleSubmitEdit: propTypes.func.isRequired,
  handleCheckFileSize: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired,
  handleImageRemoval: propTypes.func.isRequired
};

export default PropertyForumReplyDisplay;
