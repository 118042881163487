import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import Star from '../../assets/images/Star.svg';
import Star from '../../assets/images/star2_full.svg';
import StarEmpty from '../../assets/images/star2.svg';
import StarHalf from '../../assets/images/star2_half.svg';

const TenantModal = ({ info, handleModal, handleAddTenant, handleDenyTenant, handleRemoveTenant, isTenant = false }) => {
  const { t } = useTranslation();

  const { id, email, profile, photo, gov_id_photo, tenant_history, tenant_avg_score } = info;
  const [showRemoveTenant, setShowRemoveTenant] = useState(false);
  const [tenantScore, setTenantScore] = useState(3);
  const [tenantComment, setTenantComment] = useState('');
  const [tenantClosure, setTenantClosure] = useState('left');

  const populateHistories = () => tenant_history.map(history => (
    <div key={history.id} className="flex-col mt-1 mb-1" style={{borderBottom: '1px solid lightgrey'}}>
      <span className='text-grey'>{t('f_streetAddress')}: </span>
      <span className='text-camel'>{history.address}</span>
      <span className='text-grey'>{t('f_termsTenantLeft')}: </span>
      <span className='text-camel'>{history.closure}</span>
      <span className='text-grey'>{t('f_landlordScore')}: </span>
      <span>{history.score}/5</span>
      <span className='text-grey'>{t('f_landlordComment')}: </span>
      <p>{history.comment}</p>
    </div>
  ));

  // const generateStars = (loops) => {
  //   const jsxData = []
  //   for (let i = 0; i < loops; i++) {
  //     jsxData.push(<img key={`Star${i}`} className='tenant-star' src={Star} alt='star rating'/>);
  //   }

  //   return jsxData;
  // }

  const generateStars = (stars, max = 5) => {
    const jsxData = [];
    const roundedStars = Math.trunc(stars);
    const leftOver = stars - roundedStars;
    let halfStar = false;
    if(Math.round(leftOver) === 1) halfStar = true;

    for (let i = 0; i < roundedStars; i++) {
      jsxData.push(<img key={`Star${i}`} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={Star} alt='star rating'/>);
    }

    if(halfStar) { jsxData.push(<img key={'HalfStar1'} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={StarHalf} alt='half star rating'/>); }

    const maxStars = max - (roundedStars + halfStar)
    for (let i = 0; i < maxStars; i++) {
      jsxData.push(<img key={`EmptyStar${i}`} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={StarEmpty} alt='empty star rating'/>);
    }

    return jsxData;
  }

  const tenantDiv = () => {
    const rentalDate = new Date(info?.appointment?.rental_date).toDateString();
    const rentalDateUntil = new Date(info?.appointment?.rental_date_until).toDateString();

    return (
    <div className='tenant-info mt-2'>
      <div className="flex-col">
        {info?.appointment && <div className='text-camel pb-1 mb-2 flex-col border-bottom'>
          <span className='text-bold'>Rental Dates Selected:</span>
          <span>{rentalDate}</span>
          <span className='text-bold'>Up until</span>
          <span>{rentalDateUntil}</span>
        </div>}
        <img className='tenant-pic' alt="Profile pic" src={photo} />
        <span className="text-grey text-center">{t('f_tenantName')}</span>
        <h2 className='text-center pb-1'>{profile.first_name} {profile.middle_name} {profile.last_name}</h2>
        {tenant_history?.length <= 0 && <span>{t('no_reviews')}</span>}
        <div className='flex-row justify-flex-center'>
          {generateStars(tenant_avg_score.score)}
        </div>
        <div className='mb-1 text-center'><span>({tenant_avg_score.score}/5) {tenant_avg_score.reviews} {t('reviews')}</span></div>

        <img className='tenant-pic' alt="Government ID" src={gov_id_photo} />
        <h3 style={{borderBottom: '1px solid lightgrey'}}>{t('f_generalInfo')}</h3>
        <div className='pt-1'>
          <span className='text-grey'>{t('f_sex')}: </span>
          <span>{profile.sex}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_dob')}: </span>
          <span>{profile.date_of_birth}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_race')}: </span>
          <span className='text-camel'>{profile.race}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_nib')}: </span>
          <span>{profile.nib}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_passport')}: </span>
          <span>{profile.passport}</span>
        </div>

        <h3 className='mt-1' style={{borderBottom: '1px solid lightgrey'}}>{t('f_contact')}</h3>
        <div className='pt-1'>
          <span className='text-grey'>{t('f_email')}: </span>
          <span>{email}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_phone')} #1: </span>
          <span>{profile.phone_numb}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_phone')} #2: </span>
          <span>{profile.mobile_numb}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_workContact')}: </span>
          <span>{profile.work_phone_numb}</span>
        </div>

        <h3 className='mt-1' style={{borderBottom: '1px solid lightgrey'}}>{t('f_family')}</h3>
        <div className='pt-1'>
          <span className='text-grey'>{t('f_relations')}: </span>
          <span className='text-camel'>{profile.relationship_status}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_spouse')}: </span>
          <span>{profile.with_spouse? 'Yes': 'No'}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_haveChildren')}: </span>
          <span>{profile.with_children? 'Yes': 'No'}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_haveBaby')}:  </span>
          <span>{profile.with_baby? 'Yes': 'No'}</span>
        </div>

        <h3 className='mt-1' style={{borderBottom: '1px solid lightgrey'}}>{t('f_income')}</h3>
        <div className='pt-1'>
          <span className='text-grey'>{t('f_annualIncome')}: </span>
          <span>{profile.annual_income}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_occupation')}: </span>
          <span className='text-camel'>{profile.occupation}</span>
        </div>
        <div>
          <span className='text-grey'>{t('f_employer')}: </span>
          <span className='text-camel'>{profile.employer}</span>
        </div>

        {/* <h3 className='mt-1' style={{borderBottom: '1px solid lightgrey'}}>{t('f_citizenRecord')}</h3>
        <div className='pt-1'>
          <span className='text-grey'>{t('f_criminalRecord')}: </span>
          <span>{profile.criminal_record? 'Yes': 'None'}</span>
        </div> */}
        {/* {profile.criminal_record && (
          <div>
            <span className='text-grey'>{t('f_reasonJailed')}: </span>
            <span>{profile.reason_jailed}</span>
          </div>
        )} */}
      </div>
      <div className="container mt-1">
        <div>
          <h3>{t('f_tenantHistory')}</h3>
          {tenant_history?.length <= 0 && <span>{t('no_history')}</span>}
          {populateHistories()}
        </div>
        <h3 className='text-center'>{t('f_overallRating')}</h3>
        {tenant_history?.length <= 0 && <div className='text-center'>{t('no_reviews')}</div>}
        <div className='flex-row justify-flex-center'>
          {generateStars(tenant_avg_score.score)}
          {/* <span>({tenant_avg_score.score}/5) {tenant_avg_score.reviews} {t('reviews')}</span> */}
        </div>
        <div className='mb-1 text-center'><span>({tenant_avg_score.score}/5) {tenant_avg_score.reviews} {t('reviews')}</span></div>
      </div>
    </div>
  )
  }

  const rateTenantDiv = (
    <div className='mt-2'>
    <form className="tenant-rate">
      <div className='flex-col mb-1'>
        <h3 className="pb-1">{t('f_rateTenant')}</h3>
        <span className='form-sub-text pb-1 text-grey'>* {t('f_giveRating_req')}</span>
        <span className='form-sub-text'>{t('f_tenantClosure')}</span>
        <select required name="closure" id="closure" value={tenantClosure} onChange={e => setTenantClosure(e.target.value)}>
          <option value="left">{t('f_movedOut')}</option>
          <option value="evicted">{t('f_evicted')}</option>
        </select>
        <span className='form-sub-text'>{t('f_tenantRating')} 1 - 5 <img className='rating-star' src={Star} alt='star rating'/></span>
        {<span className='form-sub-text text-center'>{tenantScore}</span>}
        <input type="range" id="score" name="score" min="1" max="5" value={tenantScore} onChange={e => setTenantScore(e.target.value)}></input>
        <span className='form-sub-text'>{t('f_comment')}</span>
        <textarea
          rows="4"
          cols="40"
          value={tenantComment}
          onChange={e => setTenantComment(e.target.value)}
        />
      </div>
      <div className='flex-row justify-flex-around'>
        <button type="button" onClick={() => handleModal()}>{t('f_cancel')}</button>
        <button type="button" onClick={e => handleRemoveTenant(e, { id, score: tenantScore, comment: tenantComment, closure: tenantClosure })}>{t('f_reviewTenant')}</button>
      </div>
    </form>
    </div>
  )

  return (
    <div className="modal">
      <button type="button" className="modal-bg" style={{minWidth: 'none', border: 'none'}} onClick={() => handleModal()}/>
      <div className="modal-content">
        <div className="container-md">
          <div className="">
            <button type="button" className="modal-btn" onClick={() => handleModal()}>{t('f_cancel')}</button>
            { !showRemoveTenant && tenantDiv()}
            { showRemoveTenant && rateTenantDiv}
          </div>
        </div>
        <div className='flex-row justify-flex-around pb-1'>
          {!showRemoveTenant && <button type="button" onClick={() => handleModal()}>{t('f_cancel')}</button>}
          {!isTenant && <button type="button" onClick={e => handleDenyTenant(e, id)}>{t('f_denyTenant')}</button>}
          {!isTenant && <button type="button" onClick={e => handleAddTenant(e, id)}>{t('f_acceptTenant')}</button>}
          {(isTenant && !showRemoveTenant) && <button type="button" onClick={() => setShowRemoveTenant(!showRemoveTenant)}>{t('f_removeTenant')}</button>}
        </div>
      </div>
    </div>
  );
};

TenantModal.propTypes = {
  info: propTypes.instanceOf(Object).isRequired,
  handleModal: propTypes.func.isRequired,
  handleDenyTenant: propTypes.func.isRequired,
  handleAddTenant: propTypes.func.isRequired,
  handleRemoveTenant: propTypes.func.isRequired,
};

export default TenantModal;
