import React from 'react';
import propTypes from 'prop-types';
import '../../assets/css/imageModal.css'

const ImageModal = ({ image, handleImageModal }) => {

  return (
    <div className="imageModal">
      <div className="imageModalContent">
        <img id='ModalIMG' alt="Property" src={image} />
        <button type="button" className="img-modal-btn" onClick={() => handleImageModal()}></button>
      </div>
    </div>
  );
};

ImageModal.propTypes = {
  image: propTypes.string,
  handleImageModal: propTypes.func.isRequired,
};

export default ImageModal;
