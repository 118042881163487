/* eslint-disable no-unused-vars */

import React, { useState, useRef, useEffect } from 'react';
import propTypes from 'prop-types';

const ImageCarousel = ({ images, handleImageModal }) => {
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [imageElements, setImageElements] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth || document?.documentElement?.clientWidth || document?.body?.clientWidth);
  const carouselUL = useRef(null);

  const handleImageScroll = (e, nextPage = false) => {
    e.preventDefault();
    const carouselULElem = carouselUL.current;

    if(!nextPage) {
      // carouselULElem.scrollLeft -= screenWidth;
      if(page === 1){
        setPage(maxPages);
        carouselULElem.scrollTo({
          left: carouselULElem.scrollWidth,
          behavior: "smooth",
        });
        console.log('reset Back');
      } else {
        setPage(page - 1);
        carouselULElem.scrollTo({
          left: carouselULElem.scrollLeft - (screenWidth),
          behavior: "smooth",
        });
      }
    }
    if(nextPage) {
      // carouselULElem.scrollLeft += screenWidth;
      if(page === maxPages){
        setPage(1);
        carouselULElem.scrollTo({
          left: 0,
          behavior: "smooth",
        });
        console.log('reset Forward');
      } else {
        setPage(page + 1);
        carouselULElem.scrollTo({
          left: screenWidth * page,
          behavior: "smooth",
        });
      }
    }
  }

  const populateImages = () => {
    const imageList = [];
    let increment = 1;

    if(screenWidth >= 768) {
      for (let i = 0; i < images?.length; i+=increment) {
        const imageObj = images[i];
        const imageObj2 = images[i+1];
        let listElement;

        if(i === 0){
          listElement = (<li key={imageObj.id} className='large-img-p'>
              <button type="button" onClick={() => handleImageModal(imageObj.url)}><img alt="Property" src={imageObj.url} /></button>
            </li>)
          increment = 1
        } else {
          listElement= (<li key={imageObj.id} className='small-img-p'>
              <button type="button" onClick={() => handleImageModal(imageObj.url)}><img alt="Property" src={imageObj.url} /></button>
              <button type="button" onClick={() => handleImageModal(imageObj2?.url)}>{imageObj2 && <img alt="Property" src={imageObj2?.url} />}</button>
            </li>)
          increment = 2
        }
        imageList.push(listElement);
      }
    } else {
      for (let i = 0; i < images?.length; i++) {
        const imageObj = images[i];
        let listElement;

        listElement = (<li key={imageObj.id} className='full-img-p'>
            <button type="button" onClick={() => handleImageModal(imageObj.url)}><img alt="Property" src={imageObj.url} /></button>
          </li>)

        imageList.push(listElement);
      }
    }
    // setImageElements(imageList);
    // console.log(imageElements);
    return imageList;
  };

  useEffect(() => {
    const handleScreenResize = () => {
      setScreenWidth(window.innerWidth || document?.documentElement?.clientWidth || document?.body?.clientWidth);
    };
  
    return () => window.addEventListener("resize", handleScreenResize, false);
  }, [screenWidth]);

  useEffect(() => {
    const carouselULElem = carouselUL?.current;

    if(screenWidth >= 768) {
      setMaxPages(Math.ceil(images?.length / 5));
    } else {
      setMaxPages(images?.length);
    }
    setPage(1);
    carouselULElem.scrollLeft = 0;
  }, [images?.length, screenWidth]);

  return (
    <div className='pos-relative'>
      <div className='arrow-overlay'>
        <button type='button' onClick={e => handleImageScroll(e)} className='left-pane'><i className='arrow' alt='Back Arrow' /></button>
        <button type='button' onClick={e => handleImageScroll(e, true)} className='right-pane'><i className='arrow' alt='Forward Arrow' /></button>
      </div>
      <ul ref={carouselUL} className='property-images'>
        {populateImages()}
      </ul>
      <div className='text-center'>
        <div className='photo-counter'>{images?.length} {`${images?.length > 1 ? 'Photos' : 'Photo'}`}</div>
      </div>
    </div>
  )
}

ImageCarousel.propTypes = {
  images: propTypes.instanceOf(Array),
  handleImageModal: propTypes.func.isRequired
};

export default ImageCarousel;
