/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import propTypes from 'prop-types';
import { fetchProperty, updateProperty,
         fetchPropertyGroups,
         fetchTenantInquiries, fetchPropertyResidents,
         addTenant, removeTenant, denyTenant
       } from '../../misc/apiRequests';
import '../../../assets/css/property.css';
import Checkbox from '../../presentational/checkbox';
import TenantListDisplay from './tenants/tenantListDisplay';
import PropertyGroup from './propertyGroup';
import ImageGallery from '../imageGallery';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import CoverPhoto from './coverPhoto';
import { islands } from '../../misc/jsonData/islandData';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import PageIndex from '../../presentational/pageIndex';
// import { propertiesData } from '../../misc/jsonData/propertyListingData';
// import TenantInquiryData from '../../misc/jsonData/tenantInquiryData';

const EditProperty = ({ user, setRedirect, handleMessagesModal, handleImageModal, handleModal, handleLoader }) => {
  const { t } = useTranslation();

  const [selectedProperty, setSelectedProperty] = useState({});
  const [images, setImages] = useState([]);
  const [savedImages, setSavedImages] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);
  const [propertyType, setPropertyType] = useState('');
  const [unitNumb, setUnitNumb] = useState('');
  const [settlement, setSettlement] = useState('');
  const [moveInSpecial, setMoveInSpecial] = useState('');
  const [highlights, setHighlights] = useState([]);
  const [disclaimer, setDisclaimer] = useState('');
  const [coverPhotoIndex, setCoverPhotoIndex] = useState(0);
  const [streetAddress, setStreetAddress] = useState('');
  const [poBox, setPoBox] = useState('');
  const [phoneNumb, setPhoneNumb] = useState('');
  const [phoneNumb2, setPhoneNumb2] = useState('');
  const [email, setEmail] = useState('');
  const [desc, setDesc] = useState('');
  const [fee, setFee] = useState('');
  const [advancedPaymentAmount, setAdvancedPaymentAmount] = useState('');
  const [depositExpected, setDepositExpected] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState('');
  const [squareFt, setSquareFt] = useState('');
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [rooms, setRooms] = useState();
  const [island, setIsland] = useState('');
  const [city, setCity] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [furnished, setFurnished] = useState(false);
  const [studio, setStudio] = useState(false);
  const [garage, setGarage] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [livingRoom, setLivingRoom] = useState(false);
  const [airConditioned, setAirConditioned] = useState(false);
  const [fencedIn, setFencedIn] = useState(false);
  const [backyard, setBackyard] = useState(false);
  const [gatedCommunity, setGatedCommunity] = useState(false);
  const [petsAllowed, setPetsAllowed] = useState(false);
  const [inUnitWasherAndDryer, setInUnitWasherAndDryer] = useState(false);
  const [washerAndDryerHookups, setWasherAndDryerHookups] = useState(false);
  const [dishwasher, setDishwasher] = useState(false);
  const [wheelchairAccessible, setWheelchairAccessible] = useState(false);
  const [laundryFacilities, setLaundryFacilities] = useState(false);
  const [fitnessCenter, setFitnessCenter] = useState(false);
  const [pool, setPool] = useState(false);
  const [elevator, setElevator] = useState(false);
  const [parking, setParking] = useState(false);
  const [balcony, setBalcony] = useState(false);
  const [upstairs, setUpstairs] = useState(false);
  const [downstairs, setDownstairs] = useState(false);
  const [electricity, setElectricity] = useState(false);
  const [water, setWater] = useState(false);
  const [internet, setInternet] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [groupID, setGroupID] = useState('');

  const [propertyGroups, setPropertyGroups] = useState([]);
  const [groupPerPage, setGroupPerPage] = useState(10);
  const [groupPage, setGroupPage] = useState(1);

  const [tenantModal, setTenantModal] = useState(false);
  const [tenantInquiries, setTenantInquiries] = useState([]);
  const [tenantPerPage, setTenantPerPage] = useState(10);
  const [tenantPage, setTenantPage] = useState(1);
  const [tenantMaxPages, setTenantMaxPages] = useState(1);
  const [propertyResidents, setPropertyResidents] = useState(null);
  const [showPropertyInfo, setShowPropertyInfo] = useState(false);
  const [showTenantInfo, setShowTenantInfo] = useState(true);
  const [showPropertyGroup, setShowPropertyGroup] = useState(false);

  const uploadsInputRef = useRef(null);
  const tenantRef = useRef(null);
  const groupRef = useRef(null);
  const propertyRef = useRef(null);

  let selectedPropertyGroup = selectedProperty?.property_group;

  // const location = useLocation();
  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    if (searchParams.get('group_name') !== undefined && searchParams.get('group_name') !== null) {
      handleMenuOption('group');
    }
  }, [searchParams])

  useEffect(() => {
    // if (!sessionStorage.getItem('user')) setRedirect("/");
    // if(!user?.logged_in) { setRedirect("/"); }
  }, [user]);

  // Populate Islands and Settlements
  const populateIslands = () => islands.map((data, index) => (
    <option className='size-1' key={index} value={data.name}>{`${data.name}, Bahamas`}</option>
  ));
  const populateSettlements = () => islands.filter(x => x.name === island)[0]?.settlements.map((data, index) => (
    <option className='size-1' key={index + data} value={data}>{data}</option>
  ));
  // Fetch Property by ID
  useEffect(() => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      fetchProperty(propertyID)
        .then(response => {
          if (response.success) {
            const property = response.record;
            setSelectedProperty(property);
            setSavedImages(property.images);
            setCoverPhotoIndex(property.cover_photo_index || 0);
            // setImages(property.images);
            setPropertyType(property.property_type);
            setUnitNumb(property.property_unit);
            setIsland(property.island);
            setCity(property.city);
            setSettlement(property.settlement || '');
            setStreetAddress(property.street_address);
            setPoBox(property.po_box);
            setPhoneNumb(property.phone_numb);
            setPhoneNumb2(property.phone_numb_2);
            setEmail(property.email);
            setDesc(property.desc || '');
            setMoveInSpecial(property.move_in_special || '');
            setDisclaimer(property.disclaimer || '');
            setFee(property.fee);
            setAdvancedPaymentAmount(property.advanced_payment_amount);
            setDepositExpected(property.deposit_expected);
            setPaymentFrequency(property.payment_frequency);
            setSquareFt(property.sq_ft);
            setBedrooms(property.bedrooms);
            setBathrooms(property.bathrooms);
            setRooms(property.rooms);
            setLatitude(property.latitude || '');
            setLongitude(property.longitude || '');
            setHighlights(property.highlights || []);
            setStudio(property.studio);
            setFurnished(property.furnished);
            setGarage(property.garage);
            setKitchen(property.kitchen);
            setLivingRoom(property.living_room);
            setAirConditioned(property.air_conditioned);
            setFencedIn(property.fenced_in);
            setBackyard(property.back_yard);
            setGatedCommunity(property.gated_community);
            setPetsAllowed(property.pets_allowed);
            setInUnitWasherAndDryer(property.in_unit_washer_and_dryer);
            setWasherAndDryerHookups(property.washer_and_dryer_hookups);
            setDishwasher(property.dishwasher);
            setWheelchairAccessible(property.wheelchair_accessible);
            setLaundryFacilities(property.laundry_facilities);
            setFitnessCenter(property.fitness_center);
            setPool(property.pool);
            setElevator(property.elevator);
            setParking(property.parking);
            setWater(property.water);
            setElectricity(property.electricity);
            setInternet(property.internet);
            setDisabled(property.disabled);
            setGroupID(property.property_group_id || '');
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [id, handleLoader, handleModal]);

  // Grab all tenant inquiries
  useEffect(() => {
    if (id) {
    const propertyID = parseInt(id, 10);

    handleLoader(true);
    fetchTenantInquiries(tenantPerPage, tenantPage, propertyID)
      .then(response => {
        if (response.success) {
          setTenantInquiries(response.records || []);
          setTenantMaxPages(response.records_params.max_pages || 1)
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
    }
  }, [id, handleLoader, handleModal, tenantPerPage, tenantPage]);

  // Grab property resident
  useEffect(() => {
    const propertyID = parseInt(id, 10);

    handleLoader(true);
    fetchPropertyResidents(propertyID)
      .then(response => {
        if (response.success) {
          setPropertyResidents(response.records);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  }, [id, handleLoader, handleModal]);

  // Grab property groups
  useEffect(() => {
    handleLoader(true);
    fetchPropertyGroups(groupPerPage, groupPage)
      .then(response => {
        if (response.success) {
          setPropertyGroups(response.records);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  }, [id, handleLoader, handleModal, groupPerPage, groupPage]);

  // Ensures that the property images uploaded doesn't go too far over 1 megabyte
  const handleCheckFileSize = e => {
    const elem = e.target;
    let overlimit = false;
    const errorList = [];
    Array.from(elem.files).forEach((x, index) => {
      if (elem.files[index].size > 3048576) {
        console.log(elem.files[index]);
        errorList.push(`${x.name} - File size too big (max size allowed: 3 megabyte)`);
        overlimit = true;
      } else { 
        const imagesList = images;
        imagesList.push(elem.files[index]);
        setImages(imagesList);
      }
    })

    if (overlimit) {
      elem.value = '';
      setImages([]);
      handleMessagesModal(errorList);
    }
  };

  const handleCheckbox = (state, setState) => {
    // e.preventDefault();
    setState(!state);
  };

  const handleCoverPhotoSelect = (i) => {
    // e.preventDefault();
    setCoverPhotoIndex(i);
  };

  // Navbar Menu Option select
  const handleMenuOption = (option = 'tenant') => {
    const tenantElem = tenantRef.current;
    const groupElem = groupRef.current;
    const propertyElem = propertyRef.current;
    // e.preventDefault();
    tenantElem.classList.remove('property-menu-selected');
    groupElem.classList.remove('property-menu-selected');
    propertyElem.classList.remove('property-menu-selected');

    if(option === 'tenant') {
      tenantElem.classList.toggle('property-menu-selected');
      setShowTenantInfo(true);
      setShowPropertyGroup(false);
      setShowPropertyInfo(false);
    } else if(option === 'group') {
      groupElem.classList.toggle('property-menu-selected');
      setShowTenantInfo(false);
      setShowPropertyGroup(true);
      setShowPropertyInfo(false);
    } else if(option === 'property') {
      propertyElem.classList.toggle('property-menu-selected');
      setShowTenantInfo(false);
      setShowPropertyGroup(false);
      setShowPropertyInfo(true);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const property = new FormData();
    property.append('property[property_type]', propertyType);
    property.append('property[property_unit]', unitNumb);
    property.append('property[street_address]', streetAddress.trim());
    property.append('property[po_box]', poBox);
    property.append('property[phone_numb]', phoneNumb);

    property.append('property[phone_numb_2]', phoneNumb2);
    property.append('property[email]', email);
    property.append('property[desc]', desc.trim());
    property.append('property[move_in_special]', moveInSpecial.trim());
    property.append('property[disclaimer]', disclaimer.trim());
    property.append('property[fee]', fee);
    property.append('property[advanced_payment_amount]', advancedPaymentAmount);
    property.append('property[deposit_expected]', depositExpected);
    property.append('property[sq_ft]', squareFt);
    property.append('property[bedrooms]', bedrooms);
    property.append('property[bathrooms]', bathrooms);
    property.append('property[rooms]', rooms);
    property.append('property[island]', island.trim());
    property.append('property[city]', city.trim());
    property.append('property[settlement]', settlement);
    property.append('property[latitude]', latitude);
    property.append('property[longitude]', longitude);
    removeImages.forEach((x) => property.append("property[remove_images][]", x))
    images.forEach((x) => property.append("property[images][]", x))
    property.append('property[cover_photo_index]', coverPhotoIndex);
    highlights.forEach((x) => property.append("property[highlights][]", x))

    // property.append('property[remove_images]', removeImages);
    // property.append('property[images]', images);
    property.append('property[furnished]', JSON.stringify(Boolean(furnished)));
    property.append('property[studio]', JSON.stringify(Boolean(studio)));
    property.append('property[garage]', JSON.stringify(Boolean(garage)));
    property.append('property[kitchen]', JSON.stringify(Boolean(kitchen)));
    property.append('property[living_room]', JSON.stringify(Boolean(livingRoom)));
    property.append('property[air_conditioned]', JSON.stringify(Boolean(airConditioned)));
    property.append('property[fenced_in]', JSON.stringify(Boolean(fencedIn)));
    property.append('property[back_yard]', JSON.stringify(Boolean(backyard)));
    property.append('property[gated_community]', JSON.stringify(Boolean(gatedCommunity)));
    property.append('property[pets_allowed]', JSON.stringify(Boolean(petsAllowed)));
    property.append('property[in_unit_washer_and_dryer]', JSON.stringify(Boolean(inUnitWasherAndDryer)));
    property.append('property[washer_and_dryer_hookups]', JSON.stringify(Boolean(washerAndDryerHookups)));
    property.append('property[dishwasher]', JSON.stringify(Boolean(dishwasher)));
    property.append('property[wheelchair_accessible]', JSON.stringify(Boolean(wheelchairAccessible)));
    property.append('property[laundry_facilities]', JSON.stringify(Boolean(laundryFacilities)));
    property.append('property[fitness_center]', JSON.stringify(Boolean(fitnessCenter)));
    property.append('property[pool]', JSON.stringify(Boolean(pool)));
    property.append('property[elevator]', JSON.stringify(Boolean(elevator)));
    property.append('property[parking]', JSON.stringify(Boolean(parking)));
    property.append('property[balcony]', JSON.stringify(Boolean(balcony)));
    property.append('property[upstairs]', JSON.stringify(Boolean(upstairs)));
    property.append('property[downstairs]', JSON.stringify(Boolean(downstairs)));
    property.append('property[water]', JSON.stringify(Boolean(water)));
    property.append('property[internet]', JSON.stringify(Boolean(internet)));
    property.append('property[electricity]', JSON.stringify(Boolean(electricity)));
    property.append('property[disabled]', JSON.stringify(Boolean(disabled)));

    if(groupID !== null) property.append('property[property_group_id]', groupID);

    handleLoader(true);
    const propertyID = parseInt(id, 10);
    updateProperty(property, propertyID)
      .then(response => {
        if (response.success) { 
          // handleMessagesModal(response.message);
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 2000
          });
          setRedirect(`/properties/${propertyID}`);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const handleSubmitTenant = e => {
    e.preventDefault();

    const property = new FormData();
    property.append('property[property_type]', propertyType);

    handleLoader(true);
    const propertyID = parseInt(id, 10);
    updateProperty(property, propertyID)
      .then(response => {
        if (response.success) { 
          // handleMessagesModal(response.message);
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 2000
          });
          setRedirect(`/properties/${propertyID}`);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    setTenantModal(false);
    return null;
  };

  const handleAddTenant = (e, tenantID) => {
    e.preventDefault();

    handleLoader(true);
    const propertyID = parseInt(id, 10);
    addTenant(parseInt(tenantID, 10), propertyID)
      .then(response => {
        if (response.success) {
          // handleMessagesModal(response.message);
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 2000
          });
          setRedirect(`/properties/edit/${propertyID}`);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    setTenantModal(false);
    return null;
  };

  const handleDenyTenant = (e, tenantID) => {
    e.preventDefault();

    handleLoader(true);
    const propertyID = parseInt(id, 10);
    denyTenant(parseInt(tenantID, 10), propertyID)
      .then(response => {
        if (response.success) { 
          // handleMessagesModal(response.message);
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 2000
          });
          setRedirect(`/properties/edit/${propertyID}`);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    setTenantModal(false);
    return null;
  };

  const handleRemoveTenant = (e, tenant) => {
    e.preventDefault();

    handleLoader(true);
    const propertyID = parseInt(id, 10);
    const ratingParams = {tenant: { id: tenant.id, score: tenant.score, comment: tenant.comment, closure: tenant.closure }};

    removeTenant(ratingParams, propertyID)
      .then(response => {
        if (response.success) { 
          // handleMessagesModal(response.message);
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 2000
          });
          setRedirect(`/properties/edit/${propertyID}`);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          });
        }
        handleLoader(false);
      });

    setTenantModal(false);
    return null;
  };

  const handleImageRemoval = (e, id) => {
    e.preventDefault();

    const idFound = removeImages.find(x => x === id);
    let newList = [];

    if (idFound) {
      newList = removeImages.filter(x => x !== id);
    } else {
      newList = removeImages;
      newList.push(id);
    }
    setRemoveImages(newList);
  };

  const handleClearImages = () => {
    const uploadsInputElem = uploadsInputRef.current;

    uploadsInputElem.value = '';
    setImages([]);
  }

  const populateGroups = () => propertyGroups.map(data => (
    <option key={data.id} value={data.id}>{data.group_name}</option>
  ));

  const populateInquiries = () => tenantInquiries.map(tenantData => (
    <TenantListDisplay
      key={tenantData.id}
      tenantInfo={tenantData}
      tenantModal={tenantModal}
      setTenantModal={setTenantModal}
      handleAddTenant={handleAddTenant}
      handleDenyTenant={handleDenyTenant}
      handleRemoveTenant={handleRemoveTenant}
    />
  ));
  const populateResidents = () => {
    if (Array.isArray(propertyResidents)) {
      return propertyResidents.map(tenantData => (
        <TenantListDisplay
          key={tenantData.id}
          tenantInfo={tenantData}
          tenantModal={tenantModal}
          setTenantModal={setTenantModal}
          handleAddTenant={handleAddTenant}
          handleDenyTenant={handleDenyTenant}
          handleRemoveTenant={handleRemoveTenant}
          isTenant={true}
        />
      ))
    }
    return (
    <TenantListDisplay
      key={propertyResidents.id}
      tenantInfo={propertyResidents}
      tenantModal={tenantModal}
      setTenantModal={setTenantModal}
      handleAddTenant={handleAddTenant}
      handleDenyTenant={handleDenyTenant}
      handleRemoveTenant={handleRemoveTenant}
      isTenant={true}
    />
  )};

  const populateImages = () => savedImages.map(img => (
    <ImageGallery key={img.id} imgInfo={img} handleImageModal={handleImageModal} handleImageRemoval={handleImageRemoval} />
  ));

  const tenantForm = (
    <div id='TenantList' className='text-center' onSubmit={handleSubmitTenant}>
      {/* <button type='button' onClick={() => setShowTenantInfo(!showTenantInfo)} className="mb-1"><h3>Hide Tenant Information</h3></button> */}
      <h3 className="pb-1">{t('tenantInfo')}</h3>
      <div className='flex-col mb-1'>
        <h5>{t('appInquiry')}</h5>
        <span className='form-sub-text pb-1 text-grey'>{t('appInquiryDesc')}</span>
      </div>
      <div className='mb-3'>
        <div className='tenant-flex'>{populateInquiries()}</div>
        {tenantMaxPages > 1 && (
          <div>
            <PageIndex setPage={setTenantPage} setPerPage={setTenantPerPage} page={tenantPage} perPage={tenantPerPage} maxPages={tenantMaxPages} />
          </div>
        )}
      </div>
      <div className='flex-col mt-1 mb-1'>
        <h5>{t('propertyTenant')}</h5>
        <span className='form-sub-text pb-1 text-grey'>{t('propertyTenantDesc')}</span>
      </div>
      <div className='tenant-flex'>{propertyResidents && populateResidents()}</div>
    </div>
  );
  const propertyForm = (
    <form className="edit-form" onSubmit={handleSubmit} encType="multipart/form-data">
    {/* <button className='mb-1' type='button' onClick={() => setShowPropertyInfo(!showPropertyInfo)}><h3>Hide Property Information</h3></button> */}
    <h3 className="pb-1">{t('propertyInfo')}</h3>
    <span className='form-sub-text pb-1 text-grey'>* {t('requiredFields')}</span>
    <h5 className=''>{t('propertyCategorize')}</h5>
    <select name="property_group" id="property_group" value={groupID} onChange={e => setGroupID(e.target.value)}>
      <option value=""></option>
      {populateGroups()}
    </select>
    {selectedProperty?.property_group_id && (
      <div className='flex-col'>
        <span className='form-sub-text'>{t('f_propertyType')}</span>
        <select required name="property_type" id="property_type" value={'apartment'} disabled>
          <option value="apartment">{t('f_opt_apartment')} {t('f_unit')}</option>
        </select>
      </div>
    )}
    {!selectedProperty?.property_group_id && (
      <div className='flex-col'>
        <span className='form-sub-text'>{t('f_propertyType')}</span>
        <select required name="property_type" id="property_type" value={propertyType} onChange={e => setPropertyType(e.target.value)}>
          <option value="apartment">{t('f_opt_apartment')}</option>
          <option value="air bnb">{t('f_opt_bnb')}</option>
          <option value="home">{t('f_opt_home')}</option>
          <option value="condo">{t('f_opt_condo')}</option>
          <option value="town house">{t('f_opt_townhouse')}</option>
        </select>
        <span className='form-sub-text'>{t('f_island')}</span>
        <select className='' required name="island" id="by_island" value={island} onChange={e => setIsland(e.target.value)}>
          {populateIslands()}
        </select>
        <span className='form-sub-text'>{t('f_settlement')}</span>
        <select className='' required name="settlement" id="by_settlement" value={settlement} onChange={e => setSettlement(e.target.value)}>
          <option value={null}></option>
          {populateSettlements()}
        </select>
        <span className='form-sub-text'>{t('f_streetAddress')}</span>
        <input
          type="text"
          value={streetAddress}
          onChange={e => setStreetAddress(e.target.value)}
          minLength="3"
          required
        />
        <span className='form-sub-text'>{t('f_PoBox')}</span>
        <input
          type="text"
          value={poBox}
          onChange={e => setPoBox(e.target.value)}
          minLength="3"
          required
        />
        <span className='form-sub-text mt-1 mb-05'>
          <a target='_blank' rel='noreferrer' href='https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop#:~:text=of%20a%20place-,On%20your%20computer%2C%20open%20Google%20Maps.,decimal%20format%20at%20the%20top.'>Googlemaps co-ordinates Help?</a>
        </span>
        <span className='form-sub-text'>{t('f_latitude')}</span>
        <input
          type="text"
          value={latitude}
          onChange={e => setLatitude(e.target.value)}
        />
        <span className='form-sub-text'>{t('f_longitude')}</span>
        <input
          type="text"
          value={longitude}
          onChange={e => setLongitude(e.target.value)}
        />
      </div>
    )}
    {selectedProperty?.property_group_id && (
      <div className='flex-col'>
        <span className='form-sub-text'>{t('f_unit')} #</span>
        <input
          type="text"
          value={unitNumb}
          onChange={e => setUnitNumb(e.target.value)}
          minLength="3"
        />
      </div>
    )}
    <span className='form-sub-text'>{t('f_sqFt')}</span>
    <input
      type="number"
      value={squareFt}
      onChange={e => setSquareFt(e.target.value)}
      required
    />
    <span className='form-sub-text'>{t('f_bedrooms')}</span>
    <div className='flex-row flex-nowrap'>
      <input
        className='mb-none'
        type="number"
        value={bedrooms}
        onChange={e => setBedrooms(e.target.value)}
        min='1'
        max='10'
        required
      />
      <button type='button' className='ml-05 mr-1' style={{minWidth: '40px'}} onClick={e => setBedrooms(parseInt(bedrooms) > 1 ? parseInt(bedrooms) - 1 : 10)}>-</button>
      <button type='button' style={{minWidth: '40px'}} onClick={e => setBedrooms(parseInt(bedrooms) < 10 ? parseInt(bedrooms) + 1 : 1)}>+</button>
    </div>

    <span className='form-sub-text'>{t('f_bathrooms')}</span>
    <div className='flex-row flex-nowrap'>
      <input
        className='mb-none'
        type="number"
        value={bathrooms}
        onChange={e => setBathrooms(e.target.value)}
        min='1'
        max='10'
        required
      />
      <button type='button' className='ml-05 mr-1' style={{minWidth: '40px'}} onClick={e => setBathrooms(parseInt(bathrooms) > 1 ? parseInt(bathrooms) - 1 : 10)}>-</button>
      <button type='button' style={{minWidth: '40px'}} onClick={e => setBathrooms(parseInt(bathrooms) < 10 ? parseInt(bathrooms) + 1 : 1)}>+</button>
    </div>
    {/* <span className='form-sub-text'>Total number of Rooms</span>
    <input
      type="number"
      value={rooms}
      onChange={e => setRooms(e.target.value)}
      required
    />               */}
    <span className='form-sub-text'>{t('f_desc')}</span>
    <textarea
      value={desc}
      onChange={e => setDesc(e.target.value)}
    />
    <span className='form-sub-text'>{t('f_moveInSpecial')}</span>
    <textarea
      value={moveInSpecial}
      onChange={e => setMoveInSpecial(e.target.value)}
    />
    <span className='form-sub-text'>{t('f_disclaimer')}</span>
    <textarea
      value={disclaimer}
      onChange={e => setDisclaimer(e.target.value)}
    />
    {/* <span className='form-sub-text'>{t('f_highlights')}</span>
    <input
      type="text"
      value={highlights}
      onChange={e => setHighlights(e.target.value)}
    /> */}

    <h4>{t('f_propertyPhoto')}</h4>
    <span className='form-sub-text text-grey'>* {t('f_propertyPhotoDesc')}</span>
    <span className='form-sub-text text-grey'>* {t('f_propertyPhotoDesc2')}</span>
    <div className='flex-row mb-1'>{populateImages()}</div>
    <input
      type="file"
      multiple
      ref={uploadsInputRef}
      alt='Property photo uploads'
      name="photo_uploads"
      accept="image/png, image/jpeg, image/jpg"
      // value={photoUpload}
      onChange={e => handleCheckFileSize(e)}
    />
    <button type='button' onClick={() => handleClearImages()} >{t('f_clearImage')}</button>

    <span className='form-sub-text mt-1'>{t('f_selectPhoto')}</span>
    <span className='form-sub-text text-grey'>* {t('f_selectPhotoDesc')}</span>
    <CoverPhoto
      allImages={savedImages}
      coverPhotoIndex={coverPhotoIndex || 0}
      handleCoverPhotoSelect={handleCoverPhotoSelect}
      imageType={'EditUnit'}
    />

    {/* <h3 className="pb-1 pt-1">Contact Information</h3>
    <span className='form-sub-text'>Leave any related contact information field blank if you want to use the contact info attached to your profile.</span>
    <span className='form-sub-text pt-1'>Phone Contact</span>
    <PhoneInput
      placeholder="Enter phone number"
      value={phoneNumb}
      onChange={setPhoneNumb}
      limitMaxLength={true}
    />
    <span className='form-sub-text'>Another Phone Contact</span>
    <PhoneInput
      placeholder="Enter phone number"
      value={phoneNumb2}
      onChange={setPhoneNumb2}
      limitMaxLength={true}
    />
    <span className='form-sub-text'>Email Linked to Property</span>
    <input
      type="text"
      value={email}
      onChange={e => setEmail(e.target.value)}
      minLength="3"
    /> */}


    <h3 className="pb-1 pt-1">{t('f_payment')}</h3>
    <span className='form-sub-text'>{t('f_fee')}</span>
    <input
      type="number"
      // value={fee}
      value={fee}
      onChange={e => setFee(e.target.value)}
      required
    />
    {/* <span className='form-sub-text'>Advanced Payment Fee</span>
    <input
      type="number"
      value={advancedPaymentAmount || 0}
      onChange={e => setAdvancedPaymentAmount(e.target.value)}
    /> */}
    <span className='form-sub-text'>{t('f_deposit')}</span>
    <span className='form-sub-text'>{t('f_depositDesc')}</span>
    <input
      type="number"
      value={depositExpected}
      onChange={e => setDepositExpected(e.target.value)}
    />

  {selectedProperty?.property_group_id && (<div className='flex-col'>
    <span className='form-sub-text'>{t('f_frequency')}</span>
    <select required name="property_payment_frequency" id="property_payment_frequency" value={paymentFrequency} onChange={e => setPaymentFrequency(e.target.value)}>
      <option value="weekly">{t('f_opt_weekly')}</option>
      <option value="monthly">{t('f_opt_monthly')}</option>
    </select></div>)}

  {!selectedProperty?.property_group_id && (<div className='flex-col'>
    <span className='form-sub-text'>{t('f_frequency')}</span>
    <select required name="property_payment_frequency" id="property_payment_frequency" value={paymentFrequency} onChange={e => setPaymentFrequency(e.target.value)}>
      <option value="once">{t('f_opt_once')}</option>
      <option value="daily">{t('f_opt_daily')}</option>
      <option value="weekly">{t('f_opt_weekly')}</option>
      <option value="monthly">{t('f_opt_monthly')}</option>
    </select></div>)}

    <h3 className="pb-1 pt-1">{t('f_amenities')}</h3>
    <div className='flex-row mb-1'>
      <Checkbox name={'furnished'} desc={''} checkValue={furnished} setCheckValue={setFurnished} handleCheckbox={handleCheckbox} />
      <Checkbox name={'studio'} desc={''} checkValue={studio} setCheckValue={setStudio} handleCheckbox={handleCheckbox} />
      <Checkbox name={'garage'} desc={''} checkValue={garage} setCheckValue={setGarage} handleCheckbox={handleCheckbox} />
      <Checkbox name={'kitchen'} desc={''} checkValue={kitchen} setCheckValue={setKitchen} handleCheckbox={handleCheckbox} />
      <Checkbox name={'living room'} desc={''} checkValue={livingRoom} setCheckValue={setLivingRoom} handleCheckbox={handleCheckbox} />
      <Checkbox name={'air conditioned'} desc={''} checkValue={airConditioned} setCheckValue={setAirConditioned} handleCheckbox={handleCheckbox} />
      <Checkbox name={'fenced in'} desc={''} checkValue={fencedIn} setCheckValue={setFencedIn} handleCheckbox={handleCheckbox} />
      <Checkbox name={'backyard'} desc={''} checkValue={backyard} setCheckValue={setBackyard} handleCheckbox={handleCheckbox} />
      <Checkbox name={'gated community'} desc={''} checkValue={gatedCommunity} setCheckValue={setGatedCommunity} handleCheckbox={handleCheckbox} />
      <Checkbox name={'pets allowed'} desc={''} checkValue={petsAllowed} setCheckValue={setPetsAllowed} handleCheckbox={handleCheckbox} />
      <Checkbox name={'in unit washer and dryer'} desc={''} checkValue={inUnitWasherAndDryer} setCheckValue={setInUnitWasherAndDryer} handleCheckbox={handleCheckbox} />
      <Checkbox name={'washer and dryer hookups'} desc={''} checkValue={washerAndDryerHookups} setCheckValue={setWasherAndDryerHookups} handleCheckbox={handleCheckbox} />
      <Checkbox name={'dishwasher'} desc={''} checkValue={dishwasher} setCheckValue={setDishwasher} handleCheckbox={handleCheckbox} />
      <Checkbox name={'wheelchair accessible'} desc={''} checkValue={wheelchairAccessible} setCheckValue={setWheelchairAccessible} handleCheckbox={handleCheckbox} />
      <Checkbox name={'laundry facilities'} desc={''} checkValue={laundryFacilities} setCheckValue={setLaundryFacilities} handleCheckbox={handleCheckbox} />
      <Checkbox name={'fitness center'} desc={''} checkValue={fitnessCenter} setCheckValue={setFitnessCenter} handleCheckbox={handleCheckbox} />
      <Checkbox name={'pool'} desc={''} checkValue={pool} setCheckValue={setPool} handleCheckbox={handleCheckbox} />
      <Checkbox name={'elevator'} desc={''} checkValue={elevator} setCheckValue={setElevator} handleCheckbox={handleCheckbox} />
      <Checkbox name={'parking'} desc={''} checkValue={parking} setCheckValue={setParking} handleCheckbox={handleCheckbox} />
      <Checkbox name={'balcony'} desc={''} checkValue={balcony} setCheckValue={setBalcony} handleCheckbox={handleCheckbox} />
      <Checkbox name={'upstairs'} desc={''} checkValue={upstairs} setCheckValue={setUpstairs} handleCheckbox={handleCheckbox} />
      <Checkbox name={'downstairs'} desc={''} checkValue={downstairs} setCheckValue={setDownstairs} handleCheckbox={handleCheckbox} />
      <Checkbox name={'internet'} desc={''} checkValue={internet} setCheckValue={setInternet} handleCheckbox={handleCheckbox} />
      <Checkbox name={'water'} desc={''} checkValue={water} setCheckValue={setWater} handleCheckbox={handleCheckbox} />
      <Checkbox name={'electricity'} desc={''} checkValue={electricity} setCheckValue={setElectricity} handleCheckbox={handleCheckbox} />
    </div>
    <div className='mb-1'>
      <span className='form-sub-text'>{t('f_disabled')}</span>
      <Checkbox name={'disabled'} desc={'Is this property publically listed?'} checkValue={disabled} setCheckValue={setDisabled} handleCheckbox={handleCheckbox} />
    </div>
    <div className='flex-row'>
      <button className="mr-auto" type="button" onClick={() => setRedirect(`/properties/${id}`)}>{t('f_back')}</button>                                                                                                                                                                                              
      <button className="ml-auto" type="submit">{t('updateProperty')}</button>
    </div>
  </form>
  );

  return (
    <div id="EditForm" className="pt-1">
      <div className="container">
        <div className='mt-2 mb-1'>
          <span className="text-grey text-camel">{selectedProperty?.property_group ? t('apartmentComplex') : selectedProperty?.property_type}</span>
          {selectedProperty?.property_group && <h2 className=''>{selectedPropertyGroup?.group_name}</h2>}
          <div className="pb-1">
            <h3 className="text-camel">{selectedProperty?.property_group ? selectedPropertyGroup?.settlement : selectedProperty?.settlement}, {}
            {selectedProperty?.property_group ? selectedPropertyGroup?.island : selectedProperty?.island}</h3>
            <div className="text-camel">
              {selectedProperty?.property_group ? selectedPropertyGroup?.street_address : selectedProperty?.street_address}, {}
            <span className="text-caps">{selectedProperty?.property_group ? selectedPropertyGroup?.po_box : selectedProperty?.po_box}</span>
          </div>
          </div>
          {selectedProperty?.property_group && <span className="text-grey text-camel">{selectedProperty?.property_unit || `${t('f_unit')} #`}</span>}
        </div>
        <div className='flex-row property-menu'>
          <button ref={tenantRef} type='button' onClick={() => handleMenuOption('tenant')} className='property-menu-selected'>{t('f_applicants')}</button>
          <button ref={groupRef} type='button' onClick={() => handleMenuOption('group')}>{t('f_groups')}</button>
          <button ref={propertyRef} type='button' onClick={() => handleMenuOption('property')}>{t('f_info')}</button>
        </div>
        {/* {!showTenantInfo && <button type='button' onClick={() => setShowTenantInfo(!showTenantInfo)} className='modal-btn'><h3>Show Tenant Information</h3></button>} */}
        {showTenantInfo && tenantForm}
        {showPropertyGroup && <PropertyGroup
          propertyGroups={propertyGroups}
          setPropertyGroups={setPropertyGroups}
          handleMessagesModal={handleMessagesModal}
          handleModal={handleModal}
          handleLoader={handleLoader}
          handleImageModal={handleImageModal} />}
        {/* {!showPropertyInfo && <button type='button' onClick={() => setShowPropertyInfo(!showPropertyInfo)} className='modal-btn'><h3>Show Property Information</h3></button>} */}
        {showPropertyInfo && propertyForm}
      </div>
    </div>
    );
};

EditProperty.propTypes = {
  user: propTypes.instanceOf(Object).isRequired,
  setRedirect: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired
};

export default EditProperty;
