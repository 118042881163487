import React, { useState } from 'react';
import propTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { sendTourRequest } from '../../misc/apiRequests';
// import { numberWithCommas } from '../../misc/misc';
import Exit from '../../../assets/images/exit.svg';
import '../../../assets/css/mailModal.css';
import Swal from 'sweetalert2';

const MailModal = ({ property, customText, handleMailModal,
                     handleLoader, handleModal, handleMessagesModal,
                     isPropertyGroup = false }) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState('');
  // const [contactPreference, setContactPreference] = useState('');
  // const [maxRent, setMaxRent] = useState('');
  // const [minBeds, setMinBeds] = useState('');
  // const [minBaths, setMinBaths] = useState('');
  // const [movingReason, setMovingReason] = useState('');
  const [customMessage, setCustomMessage] = useState(customText);

  const textForm = () => (
      <form className='mail-modal-form' onSubmit={handleSubmit}>
        <div className='flex-row justify-flex-between'>
          <div className='flex-col'>
            <span className='form-sub-text'>{t('f_fName')}</span>
            <input
              type="text"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              minLength="3"
              placeholder='First Name'
              required
            />
          </div>
          <div className='flex-col'>
            <span className='form-sub-text'>{t('f_lName')}</span>
            <input
              type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              minLength="3"
              placeholder='Last Name'
              required
            />
          </div>
        </div>

        <div className='flex-col'>
          <span className='form-sub-text'>{t('f_email')}</span>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            minLength="3"
            placeholder='email@example.com'
            required
          />
        </div>

        <div className='flex-row mb-1'>
          <div className='flex-col mr-1'>
            <span className='form-sub-text'>{t('f_moveInDate')}</span>
            <input
              style={{marginBottom: '0'}}
              type="date"
              value={date}
              onChange={e => setDate(e.target.value)}
              minLength="3"
            />
          </div>
          <div className='flex-col'>
            <span className='form-sub-text'>{t('f_phone')}</span>
            <PhoneInput
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
              // defaultCountry={"BS"}
              limitMaxLength={true}
            />
          </div>
        </div>
        {/* <div className='flex-row'>
          <div className='flex-col mr-1'>
            <span className='form-sub-text'>Contact by</span>
            <select required name="contact_preference" id="contact_preference" value={contactPreference} onChange={e => setContactPreference(e.target.value)}>
              <option value=""></option>
              <option value="phone">Phone</option>
              <option value="email">Email</option>
            </select>
          </div>
          <div className='flex-col'>
            <span className='form-sub-text'>Max Rent</span>
            <div className='flex-row'>
              <span className='size-1' style={{marginBottom: '16px'}}>$ </span>
              <input
                type="number"
                value={maxRent}
                onChange={e => setMaxRent(e.target.value)}
                minLength="3"
                required
                placeholder='$'
              />
            </div>
          </div>
        </div>

        <div className='flex-row'>
          <div className='flex-col mr-1'>
            <span className='form-sub-text'>Beds</span>
            <input
              type="number"
              value={minBeds}
              onChange={e => setMinBeds(e.target.value)}
              required
            />
          </div>
          <div className='flex-col'>
            <span className='form-sub-text'>Baths</span>
            <input
              type="number"
              value={minBaths}
              onChange={e => setMinBaths(e.target.value)}
            />
          </div>
        </div>

        <div className='flex-col'>
          <span className='form-sub-text'>Reason for Moving</span>
          <select required name="moving_reason" id="moving_reason" value={movingReason} onChange={e => setMovingReason(e.target.value)}>
            <option value=""></option>
            <option value="space">More/Less Space</option>
            <option value="job">Job Change</option>
            <option value="family">Family Event</option>
            <option value="relocation">Relocation</option>
            <option value="other">Other</option>
          </select>
        </div> */}

        <div className='flex-col'>
          <span className='form-sub-text'>{t('f_customMessage')}</span>
          <textarea
            rows="4"
            cols="42"
            value={customMessage}
            onChange={e => setCustomMessage(e.target.value)}
          />
        </div>
        <div className='text-center'>
          <button type="button" className="confirm-modal-btn mr-1" onClick={(e) => handleMailModal(e)}>{t('f_cancel')}</button>
          <button type="submit" className="confirm-modal-btn ml-1">{t('f_sendEmail')}</button>
        </div>
      </form>
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const request = { request_tour: { owner_id: property?.owner?.id,
                                      body: {
                                        group_name: isPropertyGroup ? property?.group_name : property?.property_group?.group_name,
                                        property: `${property?.island}, ${property?.settlement}, ${property?.street_address}`,
                                        name: `${firstName} ${lastName}`,
                                        email: email,
                                        phone: phone,
                                        // contact_preference: contactPreference,
                                        move_in_date: date,
                                        // max_rent: `$${numberWithCommas(maxRent)}`,
                                        // min_beds: minBeds,
                                        // min_baths: minBaths,
                                        // moving_reason: movingReason,
                                        custom_message: customMessage
                                      }
                                    } };
    sendTourRequest(request)
      .then(response => {
        if (response.success) { handleMessagesModal(response.message); handleMailModal(e); }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  };

  return (
    <div className="modal">
      <div className="mail-modal-content" style={{minHeight: 'auto'}}>
        <button type="button" className="modal-exit" onClick={(e) => handleMailModal(e)}><img src={Exit} alt='exit icon' /></button>
        <div className="container-md">
          <div className='mb-1 border-bottom'>
            <h2 style={{maxWidth: '80%'}}>{isPropertyGroup ? property?.group_name :
              `${property?.property_group?.island || property?.island}, ${property?.property_group?.settlement || property?.settlement}, ${property?.property_group?.street_address || property?.street_address}`}
            </h2>
          </div>
          {textForm()}
        </div>
      </div>
    </div>
  );
};

MailModal.propTypes = {
  property: propTypes.instanceOf(Object).isRequired,
  handleLoader: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleMailModal: propTypes.func.isRequired,
};

export default MailModal;
