/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { fetchAllProperties, fetchFeaturedProperties,
         fetchPropertiesByIsland, fetchPropertyGroupsByIsland } from '../../misc/apiRequests';
import PropertyDisplayThumb from '../../presentational/properties/propertyDisplayThumb';
import PageIndex from '../../presentational/pageIndex';
import Swal from 'sweetalert2';
// import { propertiesData } from '../../misc/jsonData/propertyListingData';

const PropertyListing = ({
  user, propertyType = null, byIsland,
  handlePropertyRequests, handlePropertyRemove, handlePropertySelect,
  setRedirect,
  handleLoader, handleModal
}) => {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [properties, setProperties] = useState([]);
  const [propertyGroups, setPropertyGroups] = useState([]);
  const showPropertyType = propertyType ? false : true

  const handleIndexChange = (e, nextPage = false, more = false) => {
    e.preventDefault();
    if(more) {return setPerPage(perPage + 10)}
    if(!nextPage && ((page - 1) !== 0)) setPage(page - 1);
    if(nextPage && ((page + 1) <= maxPages)) setPage(page + 1);
  };

  // Populate all properties
  const populateProperties = () => properties.map(propertyData => (
    <PropertyDisplayThumb
      key={propertyData.id}
      property={propertyData}
      user={user}
      handlePropertyRequests={handlePropertyRequests}
      handlePropertyRemove={handlePropertyRemove}
      handlePropertySelect={handlePropertySelect}
      setRedirect={setRedirect}
      showPropertyType={showPropertyType}
      isPropertyGroup={false}
    />
  ));

  // Populate all property groupings
  const populatePropertyGroups = () => propertyGroups.map(propertyData => (
    <PropertyDisplayThumb
      key={propertyData.id}
      property={propertyData}
      user={user}
      handlePropertyRequests={handlePropertyRequests}
      handlePropertyRemove={handlePropertyRemove}
      handlePropertySelect={handlePropertySelect}
      setRedirect={setRedirect}
      showPropertyType={showPropertyType}
      isPropertyGroup={true}
    />
  ));

  // Grab all relevant Properties by property_type
  useEffect(() => {
    handleLoader(true);
    const record = { property_type: propertyType, per_page: perPage, page: page, propertyType };
    if (propertyType === 'all') {
      fetchPropertiesByIsland(record.per_page, record.page, byIsland)
        .then(response => {
          if (response.success) {
            setProperties(response.records);
            setMaxPages(response.records_params.max_page || 1);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    } else {
      fetchFeaturedProperties(record.property_type, record.per_page, record.page)
        .then(response => {
          if (response.success) {
            setProperties(response.records);
            setMaxPages(response.records_params.max_page || 1)
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [handleLoader, handleModal, page, perPage, propertyType, byIsland]);

  // Fetch related Property Group
  useEffect(() => {
    if (propertyType === 'all') {
      handleLoader(true);
      fetchPropertyGroupsByIsland(10, 1, byIsland)
        .then(response => {
          if (response.success) {
            setPropertyGroups(response.records);
            setMaxPages(response.records_params.max_page || 1);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [handleLoader, handleModal, propertyType, byIsland])

  // USE FAKE DATA
  // useEffect(() => {
  //   setProperties(propertiesData);
  // }, [])

  return (
    <div id='PropertyThumb'>
      <div className='property-thumb'>
        <ul>
          {populateProperties()}
          {populatePropertyGroups()}
        </ul>
        {maxPages > 1 && (
          <div className='text-center'>
            <PageIndex setPage={setPage} setPerPage={setPerPage} page={page} perPage={perPage} maxPages={maxPages} />
          </div>
        )}
      </div>
    </div>
  );
};

PropertyListing.propTypes = {
  user: propTypes.instanceOf(Object),
  byIsland: propTypes.string.isRequired,
  handlePropertyRequests: propTypes.func.isRequired,
  handlePropertyRemove: propTypes.func.isRequired,
  handlePropertySelect: propTypes.func.isRequired,
  setRedirect: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
};

export default PropertyListing;