import React, { useRef } from 'react';
import propTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reportComplaint } from '../../misc/apiRequests';
import Exit from '../../../assets/images/exit.svg';
import Swal from 'sweetalert2';

const ReportModal = ({ handleLoader, handleMessagesModal, handleModal, setShowReportModal }) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  // const [priority, setPriority] = useState('');
  const [reportImages, setReportImages] = useState([]);

  const uploadsInputRef = useRef(null);

  // Handle property Review
  const handleSubmit = () => {
    const complaint = new FormData();

    complaint.append('complaint[message]', message);
    // complaint.append('complaint[priority]', priority);
    reportImages.forEach((x) => complaint.append("complaint[images][]", x))
  
    handleLoader(true);
    reportComplaint(complaint)
      .then(response => {
        if (response.success) { handleMessagesModal(response.message); setShowReportModal(false); }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  // Ensures that the report images uploaded doesn't go too far over 1 megabyte
  const handleCheckFileSize = e => {
    const elem = e.target;
    let overlimit = false;
    const errorList = [];
    Array.from(elem.files).forEach((x, index) => {
      if (elem.files[index].size > 3048576) {
        errorList.push(`${x.name} - ${t('fileBig')}`);
        overlimit = true;
      } else { 
        const imagesList = reportImages;
        imagesList.push(elem.files[index]);
        setReportImages(imagesList);
      }
    })

    if (overlimit) {
      elem.value = '';
      setReportImages([]);
      handleMessagesModal(errorList);
    }
  };

  const handleImageRemoval = (e, index) => {
    e.preventDefault();

    // const newList = reportImages.splice(index, 1);
    // setReportImages(newList);
    // uploadsInputRef.current.files = newList;
  };

  const handleClearImages = () => {
    const uploadsInputElem = uploadsInputRef.current;

    uploadsInputElem.value = '';
    setReportImages([]);
  };
  
  const populateImages = () => reportImages.map((img, index) => (
    // <ImageGallery key={img.id} imgInfo={img} handleImageRemoval={handleImageRemoval} />
    <button type="button" name={img.name} className='img-upload-btn-2 mt-1 mb-05' key={index + 'img'} onClick={(e) => handleImageRemoval(e, index)}>
      <img alt="complaint" src={URL.createObjectURL(img)} />
    </button>
  ));

  return (
    <div className="modal">
      <div className="modal-content" style={{minHeight: 'auto', maxHeight: 'none'}}>
        <button type="button" className="modal-exit" onClick={() => setShowReportModal(false)}><img src={Exit} alt='exit icon' /></button>
        <div className="container-md">
          <h2 className='mb-1'>{t('reportIssue')}</h2>
          <form className='confirm-modal-form account-form'>
            <h3 className='mb-05'>{t('descIssue')}</h3>
            <textarea
              className='size-2'
              rows="8"
              cols="42"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
            {/* <span className='form-sub-text'>Priority</span>
            <select required name="priority" id="priority" value={priority} onChange={e => setPriority(e.target.value)}>
              <option value="unknown">unknown</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select> */}
            <div className='flex-col mt-2'>
              <h3 className='mb-1'>{t('f_photo')}</h3>
              <input
                type="file"
                multiple
                ref={uploadsInputRef}
                alt='Property photo uploads'
                id='photo_uploads'
                name="photo_uploads"
                accept="image/png, image/jpeg, image/jpg"
                // value={photoUpload}
                onChange={e => handleCheckFileSize(e)}
                style={{
                  width: 'auto',
                  height: 'auto',
                  opacity: '100',
                  overflow: 'hidden',
                  position: 'relative',
                  zIndex: 'auto'
                }}
              />
              {/* <label htmlFor="photo_uploads">{t('f_uploadImage')} {uploadsInputRef?.current?.value}</label> */}
              <div className='flex-row'>{reportImages && populateImages()}</div>
              <button className='mt-1' style={{padding: '16px 8px'}} type='button' onClick={() => handleClearImages()}>{t('f_clearImage')}</button>
            </div>
          </form>
        </div>
        <div className='text-center mt-1 mb-1'>
          <button type="button" className="confirm-modal-btn mr-1" onClick={() => setShowReportModal(false)}>{t('f_cancel')}</button>
          <button type="button" className="confirm-modal-btn ml-1" onClick={() => handleSubmit()}>{t('f_submit')}</button>
        </div>
      </div>
    </div>
  );
};

ReportModal.propTypes = {
  handleModal: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  setShowReportModal: propTypes.func.isRequired,
};

export default ReportModal;
